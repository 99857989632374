.researchHubContainer{
    height: 100%;
    background-color: white;
    padding: 8px;
    grid-template-rows: 48px 1fr;
    gap:8px;
}

.researchHubTabs{
    grid-template-columns: auto auto auto;
}

.researchHubContent{
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 168px);
    min-height: 420px;
    /* min-width: 712px; */
    grid-template-rows: 300px 1fr;
    border: 2px solid lightgray;
}

.addressHubContent{
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 168px);
    min-height: 420px;
    /* min-width: 712px; */
    grid-template-rows: 375px 1fr;
    border: 2px solid lightgray;
}

.researchHubInputs{
    grid-template-rows: 64px 1fr 1fr 1fr;
    padding: 0px;
    /* background-color: #e0f2f1; */
    min-height: 300px;
}

.addressHubInputs{
    grid-template-rows: 64px 1fr 1fr 1fr 1fr;
    padding: 0px;
    /* background-color: #e0f2f1; */
    min-height: 300px;
}

.researchHubInputs .inputTitle{
    background-color: white;
    border: 2px solid lightgray;
    width: auto;
    min-width: 262px;
}

.addressHubInputs .inputTitle{
    background-color: white;
    border: 2px solid lightgray;
    width: auto;
    min-width: 262px;
}

.researchHubTitle{
    background-color: #e0f2f1;
    border: 2px solid #009688;
    width: 30vw;
    min-width: 262px;
}

.researchHubInputs .inputRow1{
    grid-template-columns: 96px 1fr 32px 1fr 32px 1fr 96px;
}

.researchHubInputs .inputRow2{
    grid-template-columns: 32px auto .8fr 32px 1fr 32px 1fr 32px;
}

.researchHubInputs .obitInputRow2{
    grid-template-columns: 16px auto .8fr 16px 1fr 16px 1fr 16px 1fr 16px;
}

.addressHubInputs .addressInputRow1{
    grid-template-columns: 96px 1fr 32px 1fr 32px 1fr 96px;
}

.addressHubInputs .addressInputRow2{
    grid-template-columns: 96px 1fr 32px 1fr 32px 1fr 96px;
}

.addressHubInputs .addressInputRow3{
    grid-template-columns: 28px auto .8fr 32px 1fr 32px 1fr 96px;
}

.researchHubInputs input[type=date] ~ svg {
    top: 36px;
    right: 4px;
}

.addressHubInputs input[type=date] ~ svg {
    top: 36px;
    right: 4px;
}

.researchHubInputs .stateInputBox{
    grid-template-rows: 1fr auto;
}

.addressHubInputs .stateInputBox{
    grid-template-rows: 1fr auto;
}

.researchHubInputs .inputSearch{
    border: 2px solid #009688;
    width: 40%;
    grid-template-columns: 1fr auto;
}

.addressHubInputs .inputSearch{
    border: 2px solid #009688;
    width: 40%;
    grid-template-columns: 1fr auto;
}

.researchHubResults{
    /* grid-template-rows: repeat(auto,auto); */
    border: 2px solid lightgray;
    background-color: whitesmoke;
    /* background-color: #e0f2f1; */
}

.researchHubResults .resultBlock{
    border-radius: 5px;
    background-color: white;
    border: 1px solid gray;
    width: 100%;
    height: 64px;
}

.researchHubResults .obitResultBlock{
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 5px;
    background-color: white;
    border: 1px solid gray;
    width: 100%;
    min-width: 570px;
    height: 96px;
}

.researchHubResults .addressResultBlock{
    padding: 20px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr;
    border-radius: 5px;
    background-color: white;
    border: 1px solid gray;
    width: 100%;
    min-width: 570px;
    height: 96px;
}

.researchHubResults .relativeResultBlock{
    padding: 20px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-radius: 5px;
    background-color: white;
    border: 1px solid gray;
    width: 100%;
    min-width: 1110px;
    height: 96px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.researchHubResults .selectedResultBlock{
    border-radius: 5px;
    background-color: #e0f2f1;
    border: 2px solid #004d40;
    width: 100%;
}

.researchHubResults .obituaryResults{
    grid-template-columns: 2fr 1fr;
}

.researchHubResults .obituaryListBox{
    max-height: 100%;
}

.researchHubResults .obituaryTextBox{
    grid-template-rows: 24px 48px 1fr;
    background-color: white;
    border: 2px solid gray;
}

.researchHubResults .addressTextBox{
    grid-template-rows: 1fr;
    background-color: white;
    border: 2px solid gray;
}

.researchHubResults .obitLinkBlock{
    border-radius: 5px;
    background-color: white;
    /* border: 2px solid lightgray; */
    width: 100%;
}

.researchHubResults .obitContentBlock{
    border-radius: 5px;
    background-color: white;
    border: 2px solid lightgray;
    width: 100%;
    /* max-width: 70%; /* Adjust the percentage as needed */
    /* margin: 0 auto; */ 
    /* word-wrap: break-word; */
}

.researchHubResults .addressContentBlock{
    border-radius: 5px;
    background-color: white;
    border: 2px solid lightgray;
    width: 100%;
    height: 100%;
}

.addressContentBlock .streetRow{
    grid-template-columns: 1fr auto auto 1fr;
}
.addressContentBlock .cityRow{
    grid-template-columns: 1fr auto auto 1fr;
}
.addressContentBlock .stateRow{
    grid-template-columns: 1fr auto auto 1fr;
}
.addressContentBlock .zipRow{
    grid-template-columns: 1fr auto auto 1fr;
}

.researchHubResults .urlStyle{
    /* color: blue;
    text-decoration: underline; */
    cursor: pointer; 
    font-weight: 500;
    color: #42a5f5;
    background-color: #e3f2fd;
    border: solid 2px transparent;
    padding: 4px;
}

.researchHubResults .urlStyle:hover{
    /* color: darkblue; */
    border-color: #42a5f5;
}

.researchHubResults .obitTextContent{
    line-height: 1.7;
}

.relativeResults{
    padding-top: 0px;
}

.relativeExport {
    grid-template-columns: 1fr auto 8px auto 1fr;
    position: sticky;
    top: 0; 
    z-index: 1000;
    background-color: white;
    padding: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Landing Page */

.researchHubLandingContainer{
    height: 100%;
    background-color: white;
    padding: 8px;
    grid-template-rows: 1fr;
    gap:8px;
}

.researchHubLandingContainer .landingPageContainer{
    height: 100%;
    margin-top: 10px;
    grid-template-rows: 48px 20% 35% 30%;
}

.researchHubLandingContainer .landingPageOptions{
    height: 100%;
    gap: 20px;
}

.researchHubLandingContainer .landingPageTile{
    height: 300px;
    width: 20vw;
    border: 2px solid gray;
}

.researchHubLandingContainer .landingPageTile:hover{
    background-color: #e0f2f1;;
}