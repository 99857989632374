.uploadContainer{
    height: 100%;
    grid-template-rows: 60px 1fr;
    background-color: white;
}

/* Upload Title Row */
.uploadTitle{
    grid-template-columns: 20% 20% 20% 40%;
}

.titleBtnsGrid{
    min-width: 304px;
    grid-template-columns: 1fr auto auto;
}

.manyTitleBtnsGrid{
    min-width: 304px;
    grid-template-columns: 1fr 84px auto auto;
}

.helpLink:visited{
    color: #42a5f5;
}

.helpLink:hover{
    color: #1769aa;
}

.fileTemplateBtn{
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    font-size: 16px;    
    white-space: nowrap;
}

.templateGap{
    margin-left: 8px;
}

.viewbarGrid{
    grid-template-columns: auto 1fr;
    padding-left: 8px;
}

/* #004d40  #26a69a    #42a5f5   #e3f2fd*/
.uploadViewBar{
    width:min-content;
    height:min-content;
    display: grid;
    border:solid 2px #26a69a;
    border-radius: 5px;
    overflow: hidden;
    padding:4px;
    gap:8px;
  }
  
  .uploadViewBar .viewBarBtn{
    height: min-content;
    width: max-content;
    line-height: 1;
    color:dimgray;
    border-radius: 5px;
    padding:4px;
    border:solid 2px transparent;
  }
  
  .uploadViewBar .viewBarBtn:not(.active):hover{
    background-color:#e0f2f1;
    border:solid 2px #004d40;
    color:#004d40;
  }
  
  .uploadViewBar .viewBarBtn.active{
    background-color: #004d40;
    font-weight: 500;
    color:white;
  }
  
  .uploadViewBar .viewBarBtn .prompt{
    grid-template-columns: 1fr auto;
    gap:8px;
  }

/*Tab Row*/
.uploadTabs{
    grid-template-columns: 100%;
}

.fileTab{
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    padding: 10px; 
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
}

.singleTab{
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    padding: 10px; 
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
}

.tabUnselected:hover{
    background-color: rgb(221, 221, 221);
    cursor: pointer;
}

.tabSelected{
    background-color: #42a5f5;
    opacity: .8;
    cursor: default;
}

.rowContainer{
    height: 100%;
}

/* File Share Box */
.fileShareBox{
    grid-template-rows: 28px 1fr 64px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.fileShareTitle{
    height: 28px;
    grid-template-columns: 1fr 8px 1fr;
}

.fileShareHeader{
    white-space: nowrap;
}

.removeFileGrid{
    grid-template-columns: 1fr auto 16px auto;
    padding-bottom: 1px;
}

.removeFile{
    height: 28px;
    padding:8px;
    cursor: pointer;
    border: 2px solid dimgray;
    white-space: nowrap;
}

.removeFile:hover{
    background-color: #F0F0F0;
}

.removeFileDisabled{
    height: 28px;
    padding:5px;
    border: 2px solid lightgray;
    white-space: nowrap;
}

.displayErrorsGrid{
    grid-template-columns: auto auto auto auto 1fr;
    padding-bottom: 1px;
}

.displayErrorsArea{
    grid-template-columns: auto 1fr;
    padding-bottom: 1px;
}

.displayErrorsBtn{
    height: 28px;
    padding:5px;
    cursor: pointer;
    border: 2px solid dimgray;
    white-space: nowrap;
}

.displayErrorsBtn:hover{
    background-color: #F0F0F0;
}

.btnDisabled{
    height: 28px;
    padding:5px;
    color: lightgray;
    border: 2px solid lightgray;
    white-space: nowrap;
}

/* Upload zone */
.uploadRow{
    grid-template-columns: 2fr 300px;
}

.dropZoneContainer{
    border: 2px solid lightgray;
    border-radius: 5px;
    padding: 36px;
}

.filePreviewContainer{
    border-radius: 5px;
    max-height: calc(100vh - 248px); 
    border-radius: 5px;
}

.reportSheetZIndex{
    z-index: 0;
}

.filePreviewContainer .report {
    padding: 0px !important;
    border: none !important;
    overflow: auto;
    grid-template-rows: 1fr;
}

.filePreviewContainer :not(.gathering) .report .list{
    grid-auto-rows: 48px;
}

.filePreviewContainer .report > :first-child{
    overflow: auto;
    border: solid 2px #E2E2E2;
    border-radius: 5px;
    grid-template-rows: 50px repeat(auto-fill, 48px);
    z-index: 0;
}

.invalidUploadContentContainer{
    grid-template-rows: 1fr 1fr;
    border: solid 2px #E2E2E2;
}

.invalidUploadContent{
    color: red;
    font-size: 24px;
}

/* .filePreviewContainer .report > :first-child.inactive{
    display: block;
    overflow: hidden;
} */

.uploadZone{
    height: 100%;
    width: 100%;
    border-style: dashed;
    border-color: lightgrey;
    border-radius: 5px;
    color:gray;
}

.uploadZone:hover{
    border-color:#26a69a;/* Green colors: #e0f2f1 #004d40 #26a69a Blue colors: #dbe3eb #1e88e5 */   
    background-color: #e0f2f1;
    cursor: pointer;
    color: #26a69a;
}

.warningBox{
    font-size: 20px;
    color:gray;
    border: solid;
    border-color:grey;
    border-width: 2px;
    padding: 8px;
    margin: 8px;
}

.uploadZone:hover .warningBox{
    border-color: #26a69a;
    color:#26a69a;
}

.stickyHeader th {
    position: sticky;
    top: 0;
    background-color: #e3f2fd;
    z-index: .5;
}

.fileShareDetails{
    grid-template-columns: 1fr auto 1fr;
    gap: 8px;
}

.fileShareDetail{
    height: 48px;
    border: 2px solid dimgray;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
}

.fileShareDetailInactive{
    height: 48px;
    border: 2px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
}

.fileNameDetail{
    max-width: 100%;
}

.fileShareIndividuals{
    border-radius: 5px;
    white-space: nowrap;
}

.hideElement{
    display: none !important;
}

/* Columns Box */

.columnsBox{
    padding-top: 0px;
    grid-template-rows: 28px 1fr 56px;
}

.noColumnsText{
    font-weight: 500;
    font-size: 24px;
    color: gray!important;
}

/* File Details */

.columnsContent{
    border: solid;
    border-color: lightgrey;
    border-width: 2px;
    overflow-y: auto;
}

.fileDetailsBox{
    overflow-y:auto;
    grid-template-rows: auto 32.5px auto 32.5px auto;
    max-height: calc(100vh - 252px);
}

.columnIconBox{
    padding-top: 8px;
    height: 64px;
}

.iconCircleGreen{
    border: 2px solid lightgray;
    border-radius: 100px;
    padding: 8px;
    background-color: #f1f8e9;
    border-color: #8bc34a;
}
.iconCircleYellow{
    border: 2px solid #fbc02d;
    border-radius: 100px;
    padding: 8px;
    background-color: #fffde7;
    border-color: #fbc02d;
}
.iconCircleRed{
    border: 2px solid #f44336;
    border-radius: 100px;
    padding: 8px;
    background-color: #ffebee;
    border-color: #f44336;
}


.iconCircleGray{
    border: 2px solid lightgray;
    border-radius: 100px;
    padding: 8px;
    background-color: white;
    border-color: lightgray;
}

.requiredColumnsBox{
    /* grid-template-columns: 1fr 1.5fr; */
    grid-template-rows: auto auto;
    border: 2px solid lightgray;
    margin: 10px;
    padding: 5px;
    background-color: white;
}

/* Color Schema for correct, partial, and incorrect */
/* .correctIncluded {
    background-color: #f1f8e9;
    border-color: #8bc34a;
} */
.correctIncluded {
    background-color: #d4efb5;
    border-color: #a1cd6d;
}
div.requiredElement.correctHeader {
    border: 2px solid #8bc34a;
    background-color: #fcfff9;
}
div.requiredElement.incorrectHeader {
    border: 2px solid #f44336;
    background-color: #ffebee;
}
/* .partialIncluded {
    background-color: #fffde7;
    border-color: #fbc02d;
} */
.partialIncluded {
    background-color: #fff4aa;
    border-color: #ffce53;
}

/* .incorrectIncluded {
    background-color: #ffebee;
    border-color: #f44336;
} */
.incorrectIncluded {
    background-color: #ffb8b8;
    border-color: #f44336;
}

.requiredElement{
    border: 2px solid rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;
}

@keyframes requiredElementUpdate {
    0% {
      background-color: transparent; 
    }
    100% {
        background-color: white;
    }
}

.animateRequiredElement{
    animation: requiredElementUpdate .7s ease-in-out;
}

/* .yellowColorSet{
    background-color: #fffde7;
    border-color: #fbc02d;
} */
.yellowColorSet {
    background-color: #fff4aa;
    border-color: #ffce53;
}

/* .greenColorSet{
    background-color: #f1f8e9;
    border-color: #8bc34a;
}
.greenColorSet {
    background-color: #d2f3ab;
    border-color: #a1cd6d;
} */
.greenColorSet {
    background-color: #d4efb5;
    border-color: #a1cd6d;
}

.grayColorSet{
    background-color: white;
    border-color: lightgray;
}

.invalidEntriesBox{
    border-width: 2px;
    border-style: solid;
    margin: 10px;
    padding: 5px;
}

/* .invalidEntriesGrid{
    border: 2px solid #fbc02d;
    grid-template-columns: 8px auto 1fr 8px;
} */
.invalidEntriesGrid{
    border: 2px solid #ffce54;
    grid-template-columns: 8px auto 1fr 8px;
    background: #fffdf1;
}

.invalidEntriesText{
    font-size: 14px;
}

.noErrorsFoundBox{
    padding: 5px;
    gap: 4px;
}

.benefInfoBox{
    grid-template-rows: repeat(1fr,8);
    border-width: 2px;
    border-style: solid;
    margin: 10px;
    padding: 5px;
}

.errorText{
    color:red;
}

.saveUploadBtn{
    padding-top: 8px;
    cursor: pointer;
}

.saveUploadInactive{
    padding-top: 8px;
    grid-template-columns: 1fr 24px;
}

.saveUploadInactiveBorder{
    border: solid;
    border-color:lightgrey;
    color: lightgrey;
    border-width: 2px;
}

.savePreviewBtn{
    height: 95%;
    width: fit-content;
    margin-left: 10px;
    margin-right: 10px;
}

.savePreviewBtn:hover{
    cursor: pointer;
}

.DisplayErrorBtn{
    border-color: #ffca28;
    color:#ffc107;
}

.DisplayErrorBtn:hover{
    border-color: #ffca28;
    color:#ffc107;
    background-color: #fff8e1;
}

/* Info Row */
.infoRow{
    grid-template-columns: 3fr 2fr;
    /* max-height: 315px; */
}

.templateBox{
    border: solid;
    border-color: lightgrey;
    border-width: 2px;
}

.templateBox .templateTitle{
    font-size:20px;
    border-bottom:solid; 
    border-width: 2px;
    border-color:lightgrey;
}

.templateInfo{
    grid-template-columns: 1fr 1fr;
}

/*Required List*/
.list-container {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 225px;
    margin: 0 auto;
    text-align: left;
  }
  
  .list-item {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: .9; /* Adjust the line height */
  }
  
  .list-item::before {
    content: "\2022"; /* Use a bullet point */
    margin-right: 5px;
    color: #333;
    font-weight: bold;
  }

  /*User Agreement*/

.userAgreementBox{
    border: solid;
    border-color: lightgrey;
    border-width: 2px;
}

.userAgreementBox .userAgreementTitle {
    font-size:20px; 
    /* border-bottom:solid;  */
    border-width:2px; 
    border-color:lightgrey;
}

/* Single Upload Page */

.singleUploadContainer{
    padding-top: 8px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 80% 1fr;
}

.singleUploadContainer input[type=date] ~ svg {
    top: 36px;
    right: 4px;
}

.internalBlock{
    height: fit-content;
    min-width: 435px;
    border: solid 2px #009688;
    border-radius: 5px;
    margin: 5px;
    background-color: #e0f2f1;
}

.internalSection{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.participantBlock{
    height: fit-content;
    min-width: 435px;
    border: solid 2px #009688;
    border-radius: 5px;
    margin: 5px;
    background-color: #e0f2f1;
}

.nameSection{
    grid-template-columns: 1fr 1fr 1fr .5fr .5fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.genderSection{
    grid-template-columns: 1fr minmax(130px, auto) .8fr 1.7fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.genderBox{
    height: 100%;
    max-height: 64px;
}
.genderLabel{
    height: 32px;
    padding-bottom: 4px;
}

.genderField{
    height: 32px;
    width: 100%;
    color: black;
    font-size: 18px;
    border: solid 1px gainsboro;
}

#gender-select:focus {
    border-color: #1e88e5;
    background-color: whitesmoke; 
    outline: none;
}

.dobField {
    position: relative;
    min-width: 165px;
}

.dobLabel {
    margin-bottom: 5px;
}

.inputFont{
    padding-left: 8px;
    font-size: 18px;
    font-weight: 400;
    color: gray;
}

.inputBox{
    height: 32px;
    padding: 8px;
    border: gainsboro solid 1px;
}

.inputBox input.active{
    width: 100%;
    height: 32px;
    border-style: none;
    background-color: white;
    padding: 8px;
    border: solid 1px gainsboro;
    color:black;
}

.locationSection{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.addBeneficiary{
    border: solid 2px grey;
    padding: 10px;
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
    color:dimgrey;
    font-weight: 500;
    background-color: white;
}

.addBeneficiary:hover{
    color:#004d40
}

.removeBeneficiary{
    border: solid 2px #009688;
    padding: 8px;
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
    color:dimgray;
    font-weight: 500;
    background-color: white;
}

.removeBeneficiary:hover{
    color:#004d40
}

.addBRow{
    grid-template-columns: auto auto auto;
    height: 60px;
}

.beneficiaryBlock{
    height: fit-content;
    min-width: 435px;
    border: solid 2px #009688;
    border-radius: 5px;
    margin: 5px;
    background-color: #e0f2f1;
}

.bTitleRow{
    grid-template-columns: 1fr 1fr;
}

.bNameSection{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.bGenderSection{
    grid-template-columns: auto 1fr 1fr 1.2fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.bLocationSection{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.bRelationshipSection{
    grid-template-columns: 1fr 3.05fr;
    width:100%;
    padding-left: 20px;
    padding-right: 20px;
}

.singleUploadBtn{
    font-size: 20px;
    width: 50%;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.singleUploadBtnInactive{
    border: solid;
    border-color:lightgrey;
    color: lightgrey;
}

.suffixInputSize{
    min-width: 65px;
}

.inputSize{
    min-width: 82px;
}

.invalidField{
    border-color: #EF5350;
}

.saveUploadBox{
    grid-template-rows: 30% 70%
}

/* User Agreement Overlay */
.userAgreementBox{
    background-color: whitesmoke;
    border: 2px solid lightgray;
    padding: 20px;
}

.userAgreementButtons{
    grid-template-columns: 1fr 136px 64px 136px 1fr;
}

.redText{
    color:red;
}

.redBackground{
    background-color: #ffcdd2;
}

.greenText{
    color:#66bb6a;
}

/* SFTP Page */
.sftpContainer{
    width: 100%;
    grid-template-rows: 2fr 3fr;
    /* grid-template-columns: 1fr 8fr 1fr; */
}

.sftpSaveBtn{
    grid-template-columns: 1fr 3fr 1fr;
}

.sftpTitleGrid{
    grid-template-columns: 1fr auto;
}

.sftpSummaryBlock{
    border: solid 2px lightgray;
    background-color: white;
}

.sftpUploadBtns{
    grid-template-columns: 1fr 1fr;
    gap: 70px;
}

.sftpFileBlock{
    border: solid 2px gray;
    width: 90vw;
    height: 100%;
    /* padding: 100px; */
    /* padding-left: 300px;
    padding-right: 300px; */
    background-color: #e0f2f1;
}

.sftpButtons{
    grid-template-columns: 1fr 136px 64px 136px 1fr;
}

.sftpFileSection{
    height: 100%;
    grid-template-columns: 1fr 1fr;
    /* border: 2px solid lightgray; */
}

.sentFileSection{
    background-color: whitesmoke;
    /* border-right: 0.5px solid lightgray; */
    /* grid-template-rows: 40px repeat(7, minmax(64px, 64px)); */
    max-height: 48vh;
    overflow: auto; 
}

.retrievableFileSection{
    background-color: whitesmoke;
    /* border-left: 0.5px solid lightgray; */
    /* grid-template-rows: 40px repeat(auto-fill, 64px); */
    max-height: 48vh;
    overflow: auto; 
}

.sentFileTitle{
    grid-template-columns: 1fr auto 1fr;
    background-color: #e0f2f1;
    border: 2px solid gray;
    width: 98%;
    margin-left: 1%;
    position: sticky;
    top: 0;
}

.retrievableFileTitle{
    grid-template-columns: 1fr auto 1fr;
    background-color: #e0f2f1;
    border: 2px solid gray;
    width: 98%;
    margin-left: 1%;
    position: sticky;
    top: 0;
    z-index: 5;
}

.sentSectionRow{
    border: 2px solid lightgray;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    background-color: white;
    width: 98%;
    margin-left: 1%;
}

/* .sentSectionRow:hover{
    background-color: #e0f2f1;
} */

.retrievableSectionRow{
    --progress: 10%;
    position: relative;
    overflow: hidden;

    border: 2px solid lightgray;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    background-color: white;
    width: 98%;
    margin-left: 1%;
    z-index: 0;
}

.retrievableSectionRow .fileDetail{
    z-index: 2;
}

.retrievableDownloadBar{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background: linear-gradient(90deg, #b5e2e0, #009688); 
    z-index: 1; 
    transition: width 0.1s ease;
}

.sentSectionEmpty{
    border: 2px solid lightgray;
    width: 98%;
    height: 99%;
}

.retrievableSectionEmpty{
    border: 2px solid lightgray;
    width: 98%;
    height: 99%;
}

/* Mobile Classes */
@media all and (max-width: 768px){

    .uploadContainer{
        grid-template-rows: 56px 48px 1fr;
        overflow: auto;
    }

    .uploadContainer .viewBar {
        grid-template-columns: repeat(2, 1fr);
    }

    .rowContainer .uploadRow {
        padding-right: 8px;
        grid-template-columns: 1fr;
    }

    .mobileTitleRow{
        grid-template-columns: 1fr 1fr 1fr;
    }

    .uploadRow .columnsBox{
        padding-right: 0px;
        grid-template-rows: 0px 1fr 56px;
    }

    .filePreviewContainer{
        border-radius: 5px;
        max-height: calc(100vh - 320px); 
        border-radius: 5px;
    }

    .dropZoneContainer{
        border: 2px solid lightgray;
        border-radius: 5px;
        padding: 16px;
    }

    .fileShareDetails{
        grid-template-columns: auto 1fr auto;
        padding-right: 8px;
        gap: 8px;
    }

    .fileNameDetail{
        max-width: 54vw;
    }

    .fileDetailsBox{
        overflow-y:auto;
        grid-template-rows: auto 32.5px auto 32.5px auto;
        max-height: calc(100vh - 300px);
        background-color: white;
    }

    .singleUploadContainer{
        padding-top: 8px;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr; 
    }

    .singleMobileTitle{
        grid-template-columns: 1fr 1fr 1fr;
    }

    /* Single Upload */

    .internalBlock{
        height: fit-content;
        min-width: 0px;
        border: solid 2px #009688;
        border-radius: 5px;
        margin: 5px;
        background-color: #e0f2f1;
    }

    .participantBlock{
        height: fit-content;
        min-width: 0px;
        border: solid 2px #009688;
        border-radius: 5px;
        margin: 5px;
        background-color: #e0f2f1;
    }

    .beneficiaryBlock{
        height: fit-content;
        min-width: 0px;
        border: solid 2px #009688;
        border-radius: 5px;
        margin: 5px;
        background-color: #e0f2f1;
    }

    .internalSection{
        grid-template-columns: 1fr 1fr;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .nameSection{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .genderSection{
        grid-template-columns: 1fr auto .8fr ;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .locationSection{
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .bNameSection{
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .bGenderSection{
        grid-template-columns: auto 1fr 1fr;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .bLocationSection{
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

     /* SFTP Upload */

 
    
    .sentFileSection{
        background-color: whitesmoke;
        /* border: 1px solid gray; */
        /* grid-template-rows: 40px repeat(7, minmax(64px, 64px)); */
        max-height: 53vh;
        overflow: auto; 
    }
    
    .retrievableFileSection{
        background-color: whitesmoke;
        /* border: 1px solid gray; */
        /* grid-template-rows: 40px repeat(auto-fill, 64px); */
        max-height: 53vh;
        overflow: auto; 
    }

}