.reportingPage{
    text-align: center;
    min-width: calc(100% - 12vh);
    display: inline-block;
    position: relative;
}

.reportingHub{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    grid-template-rows: 48px 1fr;
}

.reportingHub .actionBar.inherited{
    grid-template-columns: auto 1fr 1fr;
    gap: 8px;
}
.reportingHub .actionBar.generated{
    grid-template-columns: auto 1fr 1fr;
    gap: 8px;
}
.reportingHub .actionBar.dynamic{
    grid-template-columns: auto 1fr 1fr;
    gap: 8px;
}

.reportingHub .fullActionBar.inherited{
    grid-template-columns: auto 1fr auto;
    gap: 8px;
}
.reportingHub .fullActionBar.generated{
    grid-template-columns: auto 1fr auto;
    gap: 8px;
}
.reportingHub .fullActionBar.dynamic{
    grid-template-columns: auto 1fr auto;
    gap: 8px;
}

.reportingHub .actionBar .reportSheetTitle{
    grid-template-columns: repeat(3, minmax(164px, 1fr));
    /* min-width: 495px; */
    gap: 16px;
}

.deleteReportBtn{
    width:auto;
    padding: 8px;
    border-color: #c62828;
    color: #c62828;
}

.deleteReportBtn:hover{
    background-color: #ffebee;
    border-color: #c62828;
}

.reportingHub .actionBar .reportSheetIndividualTitle{
    grid-template-columns: repeat(3, minmax(164px, 1fr));
    /* min-width: 495px; */
    gap: 16px;
}
.reportingHub .fullActionBar .reportSheetIndividualTitle{
    grid-template-columns: repeat(3, minmax(164px, 1fr));
    /* min-width: 495px; */
    gap: 16px;
}

.reportingHub .actionBar .btnOptions{
    grid-template-columns: minmax(auto, 150px) auto auto;
}

.reportingHub .actionBar .reportListHeader{
    width: 300px;
    grid-template-columns: auto 1fr;
}

.reportingHub .fullActionBar .reportSheetTitle{
    grid-template-columns:1fr 1fr 1fr;
    /* min-width: 495px; */
    gap: 16px;
}

.reportingHub .fullActionBar .btnOptions{
    grid-template-columns: minmax(auto, 150px) auto auto;
}

.reportingHub .fullActionBar .reportListHeader{
    width: 300px;
    grid-template-columns: auto 1fr;
}

.reportingHub .reportBuilder{
    grid-template-columns: 300px 1fr 0px;
    max-height: calc(100vh - 144px);
}
.reportingHub .reportListClosed {
    padding-left: 0px;
    grid-template-columns: 0px 1fr;
}
.reportingHub .reportListOpen{
    grid-template-columns: 300px 1fr;
}

.reportingHub .reportBuilder .reportAttributes{
    grid-template-rows: 48px 1fr;
    gap:8px;
}
.reportListTopBar{
    grid-template-rows: 50px 1fr;
}
.reportingHub .reportBuilder .report{
    grid-template-rows: 1fr 36px;
    gap: 8px;
    /* overflow: auto; */
}

.reportingHub .reportBuilder .reportProfileOpen{
    grid-template-columns: minmax(492px, 1fr) 1fr;
}

.reportingHub .reportBuilder .reportProfileClosed{
    grid-template-columns: 1fr;
}

.reportBuilder.remote{
    grid-template-rows: 48px 1fr;
    grid-template-columns: 1fr;
}
.reportBuilder.remote .report{
    height: inherit;
    gap: 8px;
    grid-template-rows: 1fr;
    overflow: hidden;
}

.reportBuilder.remote .report.blockType .list{
    height: inherit;
}

.reportingHub .reportBuilder .report *{
    transition: 0s;
}

.reportingHub .reportBuilder .report.edit{
    grid-template-rows: 1fr repeat(2, 36px);
    gap: 8px;
    overflow: auto;
}

.reportingHub .reportBuilder .report.gathering .loading{
    grid-template-rows: 1fr;
}

.reportingHub .reportBuilder :not(.gathering) .report .list{
    grid-auto-rows: 48px;
}

.reportingHub .reportBuilder .report > :first-child{
    overflow-x: auto;
    border: solid 2px #E2E2E2;
    border-radius: 5px;
    grid-template-rows: 50px repeat(auto-fill, 48px);
    z-index: 0;
}

.reportingHub .reportBuilder .report.blockType > :first-child{
    /* grid-template-rows: min-content; */
    /* gap:8px; */
}

.reportingHub .reportBuilder .report.blockType .list{
    border:none;
    /* height: min-content; */
    /* max-height: calc(100vh - 222px); */
}

.reportingHub .reportBuilder .report.blockType .list .columnHeader{
    border: solid 2px #E2E2E2;
    border-radius: 5px;
}
.reportingHub .reportBuilder .report.blockType .list.inactive .columnHeader{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.reportingHub .reportBuilder .report.blockType .list.inactive{
    height:unset;
}

 .reportBuilder .report > :first-child.inactive{
    display: block;
    overflow: hidden;
}

.reportingHub .reportBuilder .columnHeader{
    height: 50px;
    max-height: 50px;
    min-width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
    border-bottom:solid 2px #E2E2E2;
    border-left: solid 2px transparent;
    border-right: solid 2px transparent;
    grid-row: 1;
}
.reportBuilder .columnHeader.expanded{
    width: max-content;
}

.reportBuilder .columnHeader > .columnBlock:first-child:not(.columnBlock){
    border-left:solid 2px #e2e2e2;
}

.filePreviewContainer .list{
    grid-auto-rows: 48px;
}

.reportBuilder .list .row > div, .filePreviewContainer .list .row > div {
    border-left:solid 1px transparent;
    border-right:solid 1px transparent;
}

.reportBuilder .list .row{
    max-height: 48px;
    height:48px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

.reportBuilder .list .row, .reportBuilder .list .row *{
    transition: 0s;
}


.reportBuilder .list .row:not(:last-child), .filePreviewContainer .list .row:not(:last-child){
    border-bottom:solid 1px #EFEFEF;
}
.reportBuilder .list .row:nth-child(even), .filePreviewContainer .list .row:nth-child(even){
    background-color: white;
}

.reportBuilder .list > .row:nth-child(even):not(:hover) .cell.alt, .filePreviewContainer .list > .row:nth-child(even):not(:hover) .cell.alt{
    background-color: #F7FCFC;
}

.reportBuilder .list > .row:nth-child(odd):not(:hover) .cell.alt, .filePreviewContainer .list > .row:nth-child(odd):not(:hover) .cell.alt{
    background-color: #EBF0F0;
}

.reportBuilder .list > .row:nth-child(odd), .filePreviewContainer .list > .row:nth-child(odd){
    background-color: #F3F4F6;
}

.reportBuilder .list > .row:nth-child(even) .cell.special, .filePreviewContainer .list > .row:nth-child(even) .cell.special{
    color:gray;
}

.reportBuilder .list > .row:nth-child(odd) .cell.special, .filePreviewContainer .list > .row:nth-child(odd) .cell.special{
    color:dimgray;
}

.reportBuilder .list .row .frozen{
    position:sticky;
    z-index: 2;
    background-color: inherit;
    border-right:solid 1px gainsboro;
    height:47px;
}

.reportBuilder .list .row:not(.editing):hover .frozen{
    background-color: #e0f2f1;
    font-weight: 500;
    border-top: solid 1px #26a69a;
    border-bottom: solid 2px #26a69a;
    color: #26a69a;
}

.columnBlock.frozen{
    position:sticky;
    z-index: 2;
    background-color: inherit;
    border-right:solid 1px gainsboro;
}

.columnHeader .columnBlock.frozen.first, .row .cell.frozen.first{
    box-shadow: -2px 0 0 0 white;
}

.columnBlock.special{
    width: 50px;
    grid-template-columns: 1fr !important;
    gap: 0px !important;
}

.reportBuilder .detailBar{
    grid-template-columns: 1fr auto 1fr;
}

.reportBuilder .detailBar .section.left{
    grid-template-columns: auto 1fr;
}

.reportBuilder .checkList > div{
    grid-template-columns: 1fr 48px;
    gap: 8px;
}

.criteriaSection{
    border:solid 2px #e2e2e2;
    border-radius: 5px;
    grid-template-columns: 1fr;
    grid-auto-rows:min-content;
    gap: 8px;
}

.criteriaSection.noResults{
    grid-auto-rows:unset;
    grid-template-rows: repeat(2, min-content) 1fr;
}

.criteriaSection.noResults .message{
    
}

.criteriaSection.idle{
    grid-auto-rows: unset;
}

.criterionBlock{
    border:solid 2px #e2e2e2;
    border-radius: 5px;
    color:gray;
    grid-template-columns: 1fr;
    height:64px;
    background-color: white;
}

.criterionBlock.wIcon{
    grid-template-columns: auto 1fr auto;
}

.criterionBlock.wIcons{
    grid-template-columns: auto auto 1fr auto;
}

.criterionBlock.create{
    border:solid 2px #e2e2e2;
    border-radius: 5px;
    color:gray;
    grid-template-columns: 1fr 48px;
    height:64px;
}
.criterionBlock.active{
    /* color: #26a69a;
    border-color: #26a69a; */
    color: #26a69a;
    border-color: #26a69a;
}
.criterionBlock.create.active{
    color: #4caf50;
    border-color: #4caf50;
}

.criterionBlock .icon{
    display: none;
    border-radius: 50%;
    border:solid 2px transparent;
    aspect-ratio: 1;
}

.criterionBlock .icon:hover{
    /* color:#004d40;
    border-color:#004d40; */
    background-color: white;
    color:#004d40;
    border-color:#004d40;
}

.criterionBlock.wIcon:hover{
    grid-template-columns: auto 1fr auto;
}

.criterionBlock.wIcons:hover{
    grid-template-columns: auto auto 1fr auto;
}


.criterionBlock:hover .icon{
    display:flex;
}

.criterionBlock.create:hover{
    color: #4caf50;
    border-color:#4caf50;
    background-color: #e8f5e9;
}

.criterionBlock.create:hover svg{
    color: #4caf50;
}

.criterionBlock:hover{
    grid-template-columns: 1fr 48px;
    color: #26a69a;
    border-color: #26a69a;
    background-color: #e0f2f1;
}

.columnBlock{
    color:gray;
    height:48px;
    width:auto;
    grid-template-columns: 24px 1fr 24px;
    border-left:solid 1px transparent;
    border-right:solid 1px transparent;
    gap:0;
    width: 100%;
}

.columnBlock.alt{
    background-color: #e0f2f1;
    color:#004d40;
}


.expanded .columnBlock:not(.special){
    grid-template-columns: 24px 1fr 24px;
    gap:8px;
}

.columnBlock:hover .setFrozen, .columnBlock.frozen .setFrozen{
    display: flex;
}

.columnBlock .setFrozen svg{
    height: 18px;
    width: 18px;
}

.columnBlock .setFrozen{
    display:none;
}

.columnBlock.frozen .setFrozen{
    color: #004d40;
}

.columnBlock .setFrozen:hover{
    color:#009688;
}

.columnBlock .sort{
    color:#E2E2E2;
    grid-template-rows: repeat(2, 24px);
}

.columnBlock .sort .icon:hover{
    color:#26a69a;
}

.columnBlock .sort .active{
    color:#004d40;
}

.columnBlock.alt .sort{
    color:#80cbc4;
}

.criteriaHandler .tabBar{
    position: sticky;
    left: 0;
}

.criteriaBoard.inactive .loading{
    top: 8px;
    position: relative;
    height: calc(100% - 8px);
}

.criteriaHandler .tabs{
    width: 100%;
    gap:8px;
}
.criteriaHandler .tabs > div{
    gap:4px;
}

.criteriaHandler .actionBar{
    grid-template-columns: 256px 1fr 256px;
    position:sticky;
    left:0;
}

.criteriaHandler .fullActionBar{
    grid-template-columns: 256px 1fr 256px;
    position:sticky;
    left:0;
}

.criteriaBoard{
    grid-template-columns: 300px 1fr;
    gap:8px;
}
.criteriaBoard.grouping{
    grid-template-columns: 1fr;
}
.criteriaBoard.requirements.inactive, .criteriaBoard.properties.inactive{
    grid-template-columns: 1fr;
}
.criteriaBoard.reportType{
    grid-template-columns: 1fr;
}
.criteriaBoard.properties{
    grid-template-columns: auto minmax(500px, 1fr);
    min-width: max-content;
}

.overlay .criteriaBoard .listBoard{
    grid-template-rows: 48px 1fr;
    gap: 8px;
    width: auto;
}

.overlay .criteriaBoard .content{
    background-color: inherit;
    border:none;
}

.criteriaBoard.properties .reportDetails{
    gap:8px;
}

.criteriaBoard .actionForm.inactive{
    grid-template-rows:  1fr;
}

.criteriaBoard .listBoard .body{
    border: solid 2px #e2e2e2;
}

.criteriaBoard .listBoard .content:not(.alt){
    direction: rtl;
}

.overlay .criteriaBoard .listBoard .list .row:not(.custom, .group, .action){
    border:solid 2px #E2E2E2;
    grid-template-columns: 24px 1fr 24px;
    direction: ltr;
    color:dimgray;
    line-height: 1;
    background-color: white;
}

.overlay .criteriaBoard .listBoard .list .row.alt:not(.custom, .group){
    grid-template-columns: 24px 24px 1fr 24px;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom{
    grid-template-rows: auto;
    border:solid 2px #E2E2E2;
    color:dimgray;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom .section{
    border:solid 2px;
    background-color: white;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom .section.error{
    border-color:#ff6f00;
    color:#ff6f00;
}

/* .overlay .criteriaBoard .listBoard .list .row.alt.hovered .section{
    border-color: #f44336 !important;
    color: #f44336 !important;
} */

.overlay .criteriaBoard .listBoard .list .row.alt.custom .section.inactive{
    border-color:#E2E2E2;
    color:dimgray;
    background-color: #EFEFEF;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom .section.complete{
    border-color:#42a5f5;
    color:black;
}

.overlay .criteriaBoard .listBoard .list .row.alt .error .inputMatch {
    border-color: #ff6f00;
}


.overlay .criteriaBoard .listBoard .list .row.alt.custom.complete{
    border-color:#42a5f5;
    color:#42a5f5;
    background-color:#e3f2fd;
}

.overlay .criteriaBoard .listBoard .list .row.alt.error{
    color:#ff6f00;
    background-color: #fff8e1;
    border-color: #ffca28;
}

.overlay .criteriaBoard .listBoard .list .row.alt.error .icon{
    color:#ff6f00;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom .inputMatch{
    color: inherit !important;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom .inputMatch::placeholder {
    color: #ffca28 !important;
}

.overlay .criteriaBoard .listBoard .list .row.group{
    /* grid-template-columns: 24px 1fr 24px; */
    border: solid 2px #E2E2E2;
}
.overlay .criteriaBoard .listBoard .list .row .topBar{
    grid-template-columns: 24px 24px 1fr;
}

.overlay .criteriaBoard .listBoard .list .row:hover .topBar{
    grid-template-columns: 24px 24px 1fr 24px;
}

.overlay .criteriaBoard .listBoard .list .row.group .details{
    grid-template-columns: auto 1fr;
}

.overlay .criteriaBoard .listBoard .list .row.group.inactive{
    background-color: white;
}
.overlay .criteriaBoard .listBoard .list .row.group.inactive:hover{
    background-color: #EFEFEF;
    color:dimgray;
    border-color:dimgray;
}

.overlay .criteriaBoard .listBoard .list .row.group.selected{
    background-color:#e0f2f1;
    border-color:#26a69a;
    color:#26a69a;
}

.overlay .criteriaBoard .listBoard .list .row.alt.active{
    color: #4caf50;
    border-color: #4caf50;
    background-color: #e8f5e9;
}

.overlay .criteriaBoard .listBoard .list .row.alt.group.active,
.overlay .listBoard .interactiveList .actionBlock .row.action.selected{
    border-color:#42a5f5;
    color:#42a5f5;
    background-color: #e3f2fd;
}

.overlay .criteriaBoard .listBoard .list .row.alt.active svg{
    color: #4caf50;
}

.overlay .criteriaBoard .listBoard .list .row.alt.active:not(.group, .custom):hover{
    border-color: #f44336;
    color: #f44336;
    background-color: #ffebee;
}

.overlay .criteriaBoard .listBoard .list .row.alt.active:hover svg{
    color: #f44336;
}

.overlay .criteriaBoard .listBoard .list .row.alt.group.active svg{
    color: #42a5f5;
}

.overlay .criteriaBoard .listBoard .list .row.alt.hovered{
    border-color: #f44336 !important;
    color: #f44336 !important;
    background-color: #ffebee !important;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom.hovered .section.error:not(.inactive),
.overlay .criteriaBoard .listBoard .list .row.alt.custom.hovered .section.complete,
.overlay .criteriaBoard .listBoard .list .row.alt.custom.hovered .section .dropdown.inForm.error:not(.parent, .blank, .inactive){
    border-color: #f44336 !important;
    color: #f44336;
}

.overlay .criteriaBoard .listBoard .list .row.alt.hovered .error .inputMatch, .overlay .criteriaBoard .listBoard .list .row.alt.hovered .error .inputMatch::placeholder{
    color: #f44336 !important;
}

.overlay .criteriaBoard .listBoard .list .row.alt.hovered .icon, .overlay .criteriaBoard .listBoard .list .row.alt.group.hovered svg{
    color: #f44336 !important;
}

.overlay .criteriaBoard .listBoard .list .row.alt.custom.hovered .section .dropdown.inForm.error:not(.parent, .blank, .inactive){
    color:black !important;
}

.row .cell:not(.special) > div{
    width: calc(100% - 8px);
}
.cell .inputMatchContainer {
    height: 48px;
}

.row:hover .cell{
    height:calc(100% - 4px);
}
.cell.alt{
    border-bottom:solid 1px #e0f2f1;
}
.cell.alt, .reportBuilder .list .row .frozen.alt{
    /* background-color: rgba(224, 242, 241, 0.25); */
    color:#004d40;
}

@keyframes actionReadyBtn {
    0%, 100% {
      color:black;
    }
    50% {
      background-color: #e0f2f1; /* Middle color (e.g., green) */
      color:#26a69a;
      border-color:#26a69a;
    }
}

@keyframes actionReady {
    0%, 100% {
      border-color:#004d40;
    }
    50% {
      border-color:#26a69a;
    }
}

@keyframes actionReadyList {
    0%, 100% {
        background-color: #e0f2f1; /* Middle color (e.g., green) */
        border-color:#004d40;
    }
    50% {
        background-color: white; /* Middle color (e.g., green) */
        border-color:#26a69a;
    }
}

.actionReady{
    animation: actionReady 1.5s linear infinite; /* Adjust the duration and timing as needed */
}

.actionReady.body, .actionReady.menu{
    animation: actionReadyList 1.5s linear infinite; /* Adjust the duration and timing as needed */
}

.actionReady.btn{
    animation: actionReadyBtn 1.5s linear infinite; /* Adjust the duration and timing as needed */
}


.overlay .criteriaBoard .listBoard .list .row.actionReady:hover, .criteriaBoard .operatorsMenu .option.actionReady:hover{
    animation: unset;
    background-color:#b2dfdb;
    border-color:#004d40;
    color:#004d40;
}
.overlay .criteriaBoard .listBoard .list .row:not(.custom, .group):hover{
    background-color:#e0f2f1;
    border-color:#26a69a;
    color:#26a69a;
}

.overlay .criteriaBoard .listBoard .list .row .icon{
    display:none;
}

.overlay .criteriaBoard .listBoard .list .row:hover .icon{
    display:inline-grid;
    color:#26a69a;
}
.overlay .criteriaBoard .listBoard .list .row.actionReady:hover .icon{
    display:inline-grid;
    color:#004d40;
}
.criteriaBoard .requirement{
    gap:8px;
}

.criteriaBoard .requirement.radio{
    grid-template-columns: 48px 1fr;
}

.criteriaBoard .requirement.radio.inputReq{
    grid-template-columns: 24px 144px 1fr;
}

.criteriaBoard .requirement.radio svg{
    color: #e2e2e2;
}

.criteriaBoard:not(.activeAlteration) .requirement.radio.active svg, .criteriaBoard .actionReady .requirement.radio.active svg{
    color: #26a69a;
}

.criteriaBuilder{
    grid-template-rows:  minmax(48px, auto) 1fr;
    gap:8px;
}

.criteriaBuilder .subHeader{
    grid-template-rows: 48px;
    gap:8px;
}

.criteriaBuilder .subHeader > :first-child{
    grid-template-columns: repeat(3, 1fr);
}

.criteriaBoard .actionForm.string{
    grid-template-rows:  48px 1fr;
}

@keyframes fadeBackgroundColor {
    0% {
      background-color: #80cbc4; /* Starting color (e.g., red) */
    }
    100% {
        background-color: transparent;
    }
}

@keyframes fadeBackgroundColorContainer {
    0% {
      background-color: #80cbc4; /* Starting color (e.g., red) */
    }
    100% {
        background-color: #e0f2f1;
    }
}

@keyframes fadeBackgroundColorError {
    0% {
      background-color: #ffe082; /* Starting color (e.g., red) */
    }
    100% {
      background-color: #fff8e1; /* Ending color (transparent) */
    }
}

@keyframes fadeBackgroundColorFail {
    0% {
      background-color: #ef9a9a; /* Starting color (e.g., red) */
    }
    100% {
      background-color: #ffebee; /* Ending color (transparent) */
    }
}

.criteriaBoard .requirementGroup{
    border:solid 2px #26a69a;
    gap:8px;
    animation: fadeBackgroundColor 1s ease-in-out; /* Adjust the duration as needed */
}
.criteriaBoard .requirementGroup.container{
    animation: fadeBackgroundColorContainer 1s ease-in-out; /* Adjust the duration as needed */
}
.criteriaBoard .requirementGroup.error{
    border:solid 2px #26a69a;
    gap:8px;
    animation: fadeBackgroundColorError 1s ease-in-out; /* Adjust the duration as needed */
}
.criteriaBoard .requirementGroup.fail{
    border:solid 2px #26a69a;
    gap:8px;
    animation: fadeBackgroundColorFail 1s ease-in-out; /* Adjust the duration as needed */
}
.criteriaBoard.activeAlteration .requirementGroup, .criteriaBoard.activeAlteration .requirementGroup.container{
    background-color: #EFEFEF;
    border-color:gray;
}

.criteriaBoard.activeAlteration .dropdown, .criteriaBoard.activeAlteration .requirementGroup .requirementBlock,
.criteriaBoard.activeAlteration .requirementGroup.container .dropdown{
    border-color:gray;
}
.criteriaBoard.activeAlteration .separator.inline .andConnector, .criteriaBoard.activeAlteration .separator *{
    border-color:gray;
}
.criteriaBoard.activeAlteration .selected, .criteriaBoard.activeAlteration .inline .selected{
    background-color: gray;
}

.criteriaBoard.activeAlteration .dropdown{
    color:gray;
}

.criteriaBoard .requirementGroup.container{
    background-color:#e0f2f1;
}

.criteriaBoard .requirementGroup.container.error{
    background-color: #fff8e1;
    border-color:#ffca28;
}

.criteriaBoard .requirementBlock{
    grid-template-columns: minmax(calc(100% - 56px), 1fr) 48px;
    gap:8px;
    background-color: white;
    border:solid 2px transparent;
}

.criteriaBoard .requirementBlock > div{
    gap:8px;
}

.criteriaBoard .requirements{
    border: solid 2px #E2E2E2;
    padding: 8px;
    background-color: transparent;
}

.criteriaBoard .requirements > div{
    gap:8px;
    width: fit-content;
    min-width: 100%;
}

.criteriaBoard .requirementBlock .deleteBtn{
    color:gray;
    border:solid 2px transparent;
}

.criteriaBoard .error .requirementBlock.child:not(.blank) .deleteBtn{
    color:#ffc107;
}

.criteriaBoard .requirementBlock .deleteBtn:hover {
    border:solid 2px #f44336;
    color:#f44336 !important;
    background-color:#ffebee;
}

.inputMatch{
    font-size:18px;
    border:none;
    border-bottom:solid 2px gray;
    color:#E2E2E2;
    height:100%;
    width:95%;
}
.inputMatch:focus{
    border:none;
    outline:none;
    border-bottom:solid 2px #26a69a;
    font-size:18px;
}

.inputMatchContainer{
    position: relative;
    width:100%;
}

.inputMatchContainer svg{
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
    z-index: 0;
}

.active .inputMatch{
    color:dimgray;
    background-color: inherit;
}

.criteriaBoard .separator{
    grid-template-columns: 48px 1fr 48px;
    gap:8px;
    height:72px;
    width: 100%;
}

.criteriaBoard .separator.inline{
    height:100%;
}

.criteriaBoard .separator .andConnector{
    height:100%;
    border-left:dashed 2px gray;
}
.criteriaBoard .separator.inline .andConnector{
    border-color: #004d40;
}

.criteriaBoard .error .separator.inline .andConnector{
    border-color: #ffc107;
}

.operatorBlock{
    grid-template-columns: repeat(2, 1fr);
    width: 300px;
    border:solid 2px #26a69a;
    padding:4px;
    color:gray;
    background-color: white;
}

.addOperator{
    grid-template-columns: auto 1fr;
}

.addOperator > div{
    grid-template-columns: 24px 1fr;
    gap:8px;
    border:solid 2px #E2E2E2;
    color:gray;
    background-color:white;
}

.addOperator > div:hover{
    border-color:gray;
}

.operatorBlock .selected{
    background-color:#26a69a;
    color:white;
    border-radius: 5px;
}

.inline .operatorBlock{
    border-color:#004d40;
}

.error .inline .operatorBlock{
    border-color:#ffc107;
}

.inline .operatorBlock .selected{
    background-color:#004d40;
}

.error .inline .operatorBlock .selected{
    background-color:#ffc107;
}

.addRequirementBlock{
    grid-template-columns: 48px 1fr 48px;
    border:solid 2px dimgray;
    color:dimgray;
    width:300px;
}

.criteriaBoard .requirementGroup.container .requirementBlock{
    border: solid 2px #26a69a;
}

.addRequirementBlock:hover{
    background-color: #EFEFEF;
}

.error .child:not(.blank) .dropdown{
    border-color: #ff6f00;
}

.error .child.blank .dropdown, .fail .child.blank .dropdown{
    border-color: dimgray;
}

.fail .error.child:not(.blank) .dropdown{
    border-color: #f44336;
}

.child .dropdown{
    background-color: white;
}

/* .error.child:not(.blank) .dropdown:hover{
    background-color:#fff8e1;
} */

/* .error.child.blank .dropdown:hover, .fail .error.child.blank .dropdown:hover{
    background-color:#EFEFEF;
} */

/* .fail .error.child .dropdown:hover{
    background-color:#ffebee;
} */

.actionReady .attrTopBar{
    grid-template-columns: 24px minmax(auto, 1fr)  auto minmax(auto, 1fr)  minmax(auto, 1fr);
    gap:8px;
}

.attrTopBar{
    grid-template-columns: 24px minmax(auto, 1fr)  minmax(auto, 1fr)  minmax(auto, 1fr);
    gap:8px;
}

.attrTopBar.grouping{
    grid-template-columns: 24px minmax(auto, 1fr) minmax(auto, 1fr) 24px;
}

.attrTopBar.grouping > div:not(.positionHandler){
    grid-template-columns: minmax(120px, max-content) minmax(max-content, 1fr);
}

.attrTopBar .cancelBtn{
    color:dimgray;
}

.requirementBlock .form{
    grid-template-columns: repeat(2, 1fr);
    gap:8px;
}

/* Failed or Errors */

.criteriaBoard .requirementGroup.container.fail .requirementBlock{
    border: solid 2px gray;
}

.criteriaBoard .requirementGroup.container.fail .requirementBlock.child{
    border: solid 2px #f44336;
}

.criteriaBoard .requirementGroup.container.fail{
    background-color:#ffebee;
    border-color:#f44336;
}

.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady, .blank) {
    background-color: #ffebee;
}

.criteriaBoard .error .requirementBlock.error.child:not(.actionReady) .alert input{
    background-color: transparent;
    color:#ffc107;
    border-color: #ffecb3;
}

.criteriaBoard .error .requirementBlock.error.child:not(.actionReady) input, .criteriaBoard .fail .requirementBlock.error.child:not(.actionReady) input{
    background-color: transparent;
    color:gray;
    border-color: gray;
}

.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady, .blank) input{
    border-color: #ffcdd2;
    background-color: transparent;
    color:#f44336;
}

.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady, .blank) input:focus{
    color:#f44336;
}

.criteriaBoard .error .requirementBlock.error.child.blank:not(.actionReady) input:focus{
    color:dimgray;
}

.fail .inline .operatorBlock{
    border-color:#f44336;
}

.fail .inline .operatorBlock .selected{
    background-color:#f44336;
}

.criteriaBoard .fail .separator.inline .andConnector{
    border-color: #f44336;
}

.criteriaBoard .fail .requirementBlock.child.error:not(.actionReady, .blank) .deleteBtn:hover{
    color:#f44336;
    border:solid 2px #f44336;
    background-color: white;
}
.criteriaBoard .fail .requirementBlock.child.error:not(.actionReady, .blank) .deleteBtn{
    color:#f44336;
    border:solid 2px transparent;
}

.criteriaBoard .error .requirementBlock.error.child:not(.actionReady), .criteriaBoard .fail .requirementBlock.error.child.blank:not(.actionReady){
    background-color: #EFEFEF;
    border-color:gray;
}

.criteriaBoard .error .requirementBlock.error:not(.actionReady, .blank, .child) {
    border-color: #ffca28;
}


.criteriaBoard .error .requirementBlock.error.child:not(.actionReady, .blank) {
    border-color: #ffca28;
    background-color: white;
}

.criteriaBoard .error .requirementBlock.error.child:not(.actionReady, .blank) :not(.deleteBtn) svg{
    color: #ffca28;
}

.criteriaBoard .error .requirementBlock.error.child:not(.actionReady, .blank) .dropdown svg{
    color: black;
}


.criteriaBoard .requirementBlock.error{
    border-color:gray;
}

.criteriaBoard .error .requirementBlock.error .requirement.radio.active svg{
    color:gray;
}

.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady) .requirement.radio.active svg{
    color:#f44336;
}
.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady) .requirement.radio svg{
    color:#ffcdd2;
}

.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady, .blank) .attrTopBar .icon svg,
.criteriaBoard .fail .requirementBlock.error.child:not(.actionReady, .blank) .attrTopBar .inputMatchContainer svg{
    color:#f44336;
}

.operatorsMenu{
    position:absolute;
    top:calc(100% + 8px);
    grid-template-columns: repeat(1, minmax(50px, 1fr));
    gap: 8px;
    border: solid 2px #e2e2e2;
    z-index: 1;
    max-height: 300px;
    width: 100%;
}

.operatorsMenu.oA.tO{
    /* max-height: 192px; */
}

.operatorsMenu.invert{
    position:absolute;
    bottom:calc(100% + 8px);
    grid-template-columns: repeat(1, minmax(50px, 1fr));
    gap: 8px;
    border: solid 2px #e2e2e2;
    top: unset;
}

.operatorsMenu .option{
    border:solid 2px #E2E2E2;
    width:auto;
    height:48px;
    grid-template-columns: 1fr;
    gap:8px;
    pointer-events: all;
}

.operatorsMenu .option.alt{
    grid-template-columns: 1fr 24px;
}


.criteriaBoard .requirements .spacer{
    height: 100px;
    width:100%;
}

.dateSeparator{
    color:gray;
}

.queryStatement{
    background-color: #f3f4f6;
    color:dimgray;
    border:solid 1px gainsboro;
    max-height: 192px;
}

.leftPanel{
    gap:8px;
    grid-template-columns: 1fr;
}
.properties .leftPanel{
    gap:8px;
    grid-template-columns: repeat(2, 300px);
}


.criteriaHandler .columns{
    grid-template-rows: 48px 1fr;
    gap:8px;
    transition: 0s;
}
.criteriaHandler .columns *{
    transition: 0s;
}

.criteriaHandler .columns .options{
    /* grid-template-rows: repeat(auto-fill, minmax(48px, auto)); */
    gap:8px;
    white-space: nowrap;
    color:dimgray;
}

.criteriaHandler .columns .options .option{
    gap:8px;
    border:solid 2px gray;
    width: auto;
    cursor:pointer;
    grid-template-columns: 24px 1fr 24px;
    min-height: 48px;
    direction: ltr;
}
.criteriaHandler .columns .options .option:not(.create){
    cursor:grab;
}

.criteriaHandler .columns .options .option.freezing {
    color: #26a69a;
    border-color: #26a69a;
    background-color: #e0f2f1;
}

.criteriaHandler .columns .options .option.moving {
    border: 2px dashed dimgray;
    background-color: #EFEFEF;
    color:dimgray;
}
.criteriaHandler .columns .options .option.removing {
    border-color:#f44336;
    background-color: #ffebee;
    color:#f44336;
}

.options .placeholder {
    height: 100%; /* Or whatever height your items have */
    width: auto;
    min-width: 120px;
    border: 2px dashed gray;
    display: inline-block;
    min-height: 48px;
}
.options .placeholder.active{
    border-color: #26a69a;
    background-color: #e0f2f1;
    color:#26a69a;
}

.options .placeholder > div{
    pointer-events: none;
}

.criteriaHandler .columns .options .option .setFrozen{
    color:inherit;
}

.criteriaHandler .columns .options .option .moreOptions{
    color:inherit;
}

.criteriaHandler .columns .options .option .moreOptions svg{
    color:inherit;
}

.criteriaHandler .preferences .inputRow{
    grid-template-columns: 120px 1fr;
}
.criteriaHandler .preferences .trackBar{
    grid-template-columns: 64px repeat(3, 1fr);
}
.criteriaHandler .preferences .trackBar.tabCount2{
    grid-template-columns: 64px repeat(2, 1fr);
}

.pagination{
    gap:8px;
    color:dimgray;
}

.pagination .btn{
    border:solid 2px transparent;
    min-width: fit-content;
    /* aspect-ratio: 1/1; */
}

.pagination .btn.active{
    border-color: #26a69a;
    color:#004d40;
}
.pagination .btn:hover:not(.active){
    background-color: #26a69a;
    color:white;
}

.reportErrorBlock{
    grid-template-rows: minmax(120px, auto) 56px;
    max-width: 500px;
}

.reportErrorHandler .actionBar{
    grid-template-columns: repeat(2, 1fr);
    gap:8px;
}
.reportErrorHandler .fullActionBar{
    grid-template-columns: repeat(2, 1fr);
    gap:8px;
}

.reportTypeSelect{
    grid-template-rows: 64px 1fr;
}
.reportTypeSelect .g{
    gap:8px;
}
.reportTypeSelect .reportablePage{
    border: solid 2px #E2E2E2;
    height: inherit;
    color:dimgray;
    aspect-ratio: 1;
}
.reportTypeSelect .reportablePage.active{
    border-color: #004d40;
    color:#004d40;
    background-color: #e0f2f1;
}
.reportTypeSelect .reportablePage:hover{
    border-color: #26a69a;
    color:#26a69a;
    background-color: #e0f2f1;
}
.reportTypeSelect .reportablePage svg{
    width:48px;
    height:48px;
}

.pagination .spacer{
    width:24px;
    height: 100%;
    aspect-ratio: 1/1;
    min-width: fit-content;
}

.reportCategory{
    border:solid 2px #E2E2E2;
}

.reportCategory .option{
    color:dimgray;
    grid-template-rows: .5fr 1fr .5fr;
    height: 44px;
}

.reportCategory .option:hover{
    border-color: #004d40;
    color: #004d40;

}

.reportCategory .option.active{
    border-color: #26a69a;
    color:#26a69a;
}

.reportCategory .option .bullet{
    height: 6px;
    width: 6px;
    background-color: #26a69a;
}


.report:not(.edit) .list .row:not(.editing, .summary):hover{
    background-color: #e0f2f1;
    font-weight: 500;
    /* border: dashed 2px #26a69a; */
    color: #26a69a;
}

.report.gathering .list .row a > div{
    grid-template-columns: 24px 1fr;
    display: grid;
    gap:8px;
}

.rowMaxMenu.invert{
    position: absolute;
    bottom: calc(100% + 8px);
    grid-template-columns: repeat(1, minmax(50px, 1fr));
    gap: 8px;
    border: solid 2px #004d40;
    top: unset;
    z-index: 1;
    max-height: 300px;
    width: 100%;
    background-color: white;
}

.rowMaxMenu .option {
    border: solid 2px #004d40;
    width: auto;
    height: 48px;
    grid-template-columns: 1fr;
    gap: 8px;
    pointer-events: all;
    color:#004d40;
}

.rowMaxMenu .option:hover{
    background-color: #e0f2f1;
}

.rowMaxMenuDropdown:hover {
    background-color: #e0f2f1;
}
.rowMaxMenuDropdown {
    border: solid 2px #004d40;
    height: 36px;
    grid-template-columns: minmax(min-content, 1fr) 24px;
    gap: 8px;
    position: relative;
    width: max-content;
}

.searchBtn .btn{
    color:dimgray;
    border-radius: 50%;
    height: 36px;
    width: 36px;
}

.searchBtn.active .btn:hover{
    background-color: #e0f2f1;
    color:#004d40;
    outline:solid 2px #004d40;
}

/* .searchBtn .btn:hover{
    background-color: #EFEFEF;
    color:dimgray;
    outline:solid 2px dimgray;
} */

.searchBar .inputField{
    border-radius: 0;
    border:none !important;
}
.searchBar .inputField input{
    border-radius: 0;
    border:none !important;
    border-bottom: solid 2px gray !important;
}

.searchBar .inputField input:focus{
    border-bottom: solid 2px #26a69a !important;
    color:#009688;
}

.searchBar .inputField input:focus::placeholder{
    color:#009688;
}

.actionBar.searching{
    /* grid-template-columns: minmax(auto, 300px) auto 1fr minmax(auto, 150px); */
    grid-template-columns: minmax(auto, 300px) auto 1fr 1fr 1fr minmax(auto, 150px);
}
.fullActionBar.searching{
    /* grid-template-columns: minmax(auto, 300px) auto 1fr minmax(auto, 150px); */
    grid-template-columns: minmax(auto, 300px) auto 1fr 1fr 1fr minmax(auto, 150px);
}

.requirementBlock input{
    width:100%;
}

.searchContainer{
    grid-template-columns: 48px 1fr;
}

.systemInfo, .reportDelete{
    color:dimgray;
    grid-template-columns: auto 1fr;
}

.reportDelete:hover{
    color:#f44336;
    background-color: #ffebee;
}

.reportGroupHeader.group{
    grid-template-columns: auto 1fr 24px;
    background-color: white;
    border: solid 2px #e2e2e2;
}

.open .reportGroupHeader.group{
    grid-template-columns: auto 1fr 24px;
    border:solid 2px gray;
}

.reportGroupHeader.group .prompt{
    grid-template-columns: auto 1fr;
}

.reportGroupHeader{
    color:dimgray;
    height: min-content;
    grid-template-columns: auto 1fr 24px;
    height: 48px;
    width: 100%;
}

.reportGroupHeader.group .activityStatus svg{
    color: #009688;
}

.reportGroupHeader .activityStatus svg{
    width: 12px;
    height: 12px;
}

.reportGroupHeader:hover{
    background-color: #E2E2E2;
    border-color: gray;
}

.reportGroupHeader.year span{
    color:black;
    border-bottom:solid 2px black;
}

.reportGroupHeader.month{
    grid-template-columns: auto 1fr 24px;
    height: 36px;
}

.reportGroupHeader .activityStatus svg{
    color: #004d40;
}

.reportGroupHeader.month .activityStatus svg{
    color: #009688;
}

.reportFolder{
    background-color: #F3F4F6;
    border: 1px solid gainsboro;
    gap:4px;
    padding:4px;
}

.folderContents{
    grid-template-columns: auto 1fr;
    gap:4px
}

.reportFolder .vDivider{
    height: 100%;
    background-color: gray;
}

.folderContents .divider{
    margin:8px auto;
}

.reportsList{
    background-color: white;
    border: 2px solid gainsboro;
    gap:4px;
    padding:4px;
}

.monthContents{
    grid-template-columns: auto 1fr;
}

.reportNested{
    gap:4px;
}
.reportNested > .split {
    grid-template-columns: auto 1fr;
    gap:4px;
}

.reportingInput{
    border-radius: 0;
    border: none !important;
    border-bottom-style: solid!important;
    border-bottom-width: 2px !important;
    border-bottom-color: gray !important;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
}

.reportingInput:focus{
    outline: none;
}

.changed .reportingInput, .changed .inputMatch{
    border-bottom-style: dashed !important;
}
.changed .dropdown:not(.menu){
    border-style: dashed !important;
}

.cell .dropdown{
    width: 100%;
}

.confirmationBar{
    grid-template-columns: repeat(3, 1fr);
}

.confirmationBar .save{
    grid-template-columns: minmax(auto, 150px);
}

.reportOptions .format .preferences{
    height: min-content;
}
.reportOptions .toggles .option{
    grid-template-columns: 120px 1fr;
    height: 48px;
}

.reportOptions .toggles .option .prompt{
    grid-template-columns: 48px 1fr;
}

.reportOptions .downloads > .option{
    grid-template-columns: 120px 48px 1fr auto;
    min-height: 48px;
    width: max-content;
    grid-template-rows: 48px;
}

.reportOptions .downloads .fileTypeOption .btn.dropdown{
    border: solid 2px #004d40;
    height: 36px;
    grid-template-columns: auto 24px !important;
    gap: 8px;
    position: relative;
    width: -webkit-fill-available;
    line-height: 1;
}

.cell.special{
    cursor:default;
}

.cellLink{
    display: inline-grid;
}

/* Report Profile */

.requirementGroup.complete .requirementBlock:not(.blank, .error) .statusIcon svg{
    color:#4caf50;
}

.requirementGroup.fail .requirementBlock.error:not(.blank, .parent) .statusIcon svg{
    color:#f44336;
}

.requirementGroup .requirementBlock .statusIcon svg{
    color:gray;
}

.actionBar .profileTitle{
    /* width: 450px; */
    grid-template-columns: 1fr auto;
}
.fullActionBar .profileTitle{
    /* width: 450px; */
    grid-template-columns: 1fr auto;
}

.report .profileContainer{
    grid-template-rows: 1fr 48px;
    grid-Row: span 2;
    border: 2px solid lightgray;
    border-radius: 5px;
}

.summaryGroupBlock{
    /* display: inline-block; */
    display: grid;
    font-weight: 500;
    /* color: #42a5f5; */
    /* color:dimgray; */
    margin: auto;
    row-gap: 10px;
    height:100%;
    width: 100%;
    position: relative;
    height:auto;
    grid-template-rows:min-content;
}

.summaryGroupBlock.even{
    background-color: white;
    color:black;
}

.summaryGroupBlock.odd:not(.summarization){
    /* background-color: #e3f2fd; */
    background: linear-gradient(to left, #90caf9, #ffffff);
}

.summaryGroupBlock:not(.parent){
    /* box-shadow: 0 0 0 2px #42a5f5; */
    box-shadow: 0 0 0 2px #2196f3;
    border-top-left-radius: 5px;
    overflow: hidden;
}

.summaryGroupBlock.level-1:last-child{
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.summaryGroupBlock:not(.parent, .summarization) > .cell.value{
    border-left:solid 2px #e3f2fd;
    box-sizing: border-box;
}

.summaryGroupBlock.sibling:not(:last-of-type){
    border-bottom-left-radius: 5px;
}

.summaryGroupBlock.summarization{
    border:none;
    box-shadow: none;
}
.summaryGroupBlock.values .cell{
    height:48px;
    width: 100%;
}

.summaryGroupBlock .cell{
    height:36px;
    width: 100%;
}

.summaryGroupBlock:not(.values) > .cell{
    padding-top:8px;
    height:unset;
}

.summaryGroupBlock.values .cell.value{
    /* color:black; */
    color:black;
}

.stickyColumnsDisplay{
    position: sticky;
    top:52px;
    width: 100%;
    overflow: auto;
    height:48px;
    background-color: transparent;
    z-index: 2;
}

.stickyColumnsDisplay .groupByItem{
    border: solid 2px #2196f3;
    background-color: white;
}

.reportBuilder .report.summary .list .row.summary{
    width: 100%;
    height: 48px;
    z-index: 2;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    /* outline: solid 2px #EFEFEF; */
    border: solid 2px #2196f3;
    border-radius: 0 0 5px 5px;
    background-color:#e3f2fd;
    /* background: linear-gradient(to right, #90caf9, #e3f2fd, white); */
    background-color: #e3f2fd;
}

.fail .error:not(.blank) .positionHandler .option:not(.inactive):hover{
    color:#f44336;
    background-color: #ffebee;
}

.error:not(.blank) .positionHandler .option:not(.inactive):hover{
    color:#ff6f00;
    background-color: #fff8e1;
}

.positionHandler .option:hover{
    color:#0d47a1;
    background-color: #e3f2fd;
}

.positionHandler .option.inactive{
    color:gray;
}
.positionHandler .option.inactive:hover{
    background-color: #EFEFEF;
}

.list .emptyList{
    border:solid 2px dimgray;
    background-color: #EFEFEF;
    color:dimgray;
    height:48px;
}

.overlay .listBoard .interactiveList{
    grid-template-rows:unset !important; 
    overflow-y: visible !important;
}

.overlay .listBoard .interactiveList .actionBlock{
    margin-top: 8px;
    padding: 8px;
    border:solid 2px dimgray;
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 2;
}

.overlay .listBoard .interactiveList .actionBlock .row:not(.group, .custom){
    border: solid 2px dimgray;
    grid-template-columns: 24px 1fr 24px;
    direction: ltr;
    color: dimgray;
    line-height: 1;
    background-color: white;
    z-index: 2;
}

.criteriaHandler .columns .content.summary .options{
    border:solid 2px dimgray;
    border-radius: 5px;
}

.criteriaHandler .columns .content.summary .options.emptyList{
    background-color: #EFEFEF;
    color: dimgray;
}

.criteriaHandler .columns .content.summary .dividerBlock{
    margin-top:8px;
    height:48px;
}

.criteriaHandler .columns .content.summary .options:not(:first-child){
    margin-top:8px;
}

.criteriaHandler .criteriaBoard .emptyList .categoryTitle{
    color:dimgray;
}

.criteriaHandler .criteriaBoard .categoryTitle{
    color:black;
}

.interactiveList > * {
    transition: 0s;
}

input#searchBarInput {
    border-radius: 0 !important;
}

.remoteReportTopBar{
    grid-template-columns: repeat(3, 1fr);
}

.reportProfile{
    overflow: hidden;
    padding-left: 8px;
    padding-right: 8px;
    grid-template-rows: 50px 1fr;
}

.reportProfile .viewBarRow{
    height: fit-content;
    padding-top: 8px;
    grid-template-columns: 1fr;
}

.reportProfile .viewBarRow .viewBar{
    grid-template-columns: repeat(3, 1fr);
}

.reportProfile .viewBarRow .closeBtn{
    border-radius: 100px;
    padding: 4px;
}

.reportProfile .viewBarRow .closeBtn:hover {
    background-color: whitesmoke;
}

.reportProfile .body{
    overflow: auto;
}

.reportProfile .form{
    height: fit-content;
    gap: 64px;
}

.reportProfile .row{
    gap:8px;
    grid-template-rows: repeat(2,auto);
    height: fit-content;
}

.reportProfile .body .form .header{
    height: fit-content;
    grid-template-columns: repeat(4,auto);
}

.reportProfile .body .form .header .group {
    background-color: #e8f5e9;
    border: solid 2px transparent;
    color: #4caf50;
}

.reportProfile .body .form .row .rowData{
    gap:8px;
    grid-template-columns: repeat(2,1fr);
}

.reportProfile .obituaries{
    grid-template-columns: none;
    grid-template-rows: repeat(2, auto) 1fr;
    gap: 8px;
    width: 100%;
    overflow: auto;
    
}

.reportProfile .obituaries .header{
    gap:8px;
    grid-template-columns: repeat(4,auto);
}

.reportProfile .content .obituaryBlock{
    grid-template-columns: auto 1fr auto;
}

.reportProfile .obituaryBlock {
    border: 2px solid #e2e2e2;
    border-radius: 5px;
    color: gray;
    grid-template-columns: 1fr 48px;
    height: 64px;
    background-color: white;
}

.reportProfile .obituaryBlock.active {
    border-color: #26a69a;
    color: #26a69a;
}

.reportProfile .obituaryBlock:not(.alt):hover {
    background-color: #e0f2f1;
    border-color: #26a69a;
    color: #26a69a;
    grid-template-columns: 1fr 48px;
}

.reportProfile .description .header .contentHeader{
    grid-template-columns: auto 1fr auto;
}

.reportProfile .deathReceipt{
    max-height: 100%;
    overflow: auto;
}


.reportProfile .deathReceipt .receipt{
    background-color: #fff;
    border: 2px solid #dcdcdc;
    grid-template-rows: 36px 16px 1fr auto;
    height: 100%;
    width: 100%;
}

.reportProfile .deathReceipt .receipt .statement, .statusReport .receipt .statement {
    color: dimgray;
    font-size: 18px;
    font-weight: 100;
}

.reportProfile .receipt .form {
    color: dimgray;
    column-gap: 8px;
    font-size: 18px;
    grid-auto-rows: min-content;
    grid-template-columns: 24px 1fr 24px;
    grid-template-rows: auto;
    height: min-content;
    row-gap: 32px;

}

.reportProfile .profileContent{
    padding-top: 16px;
    max-height: calc(100vh - 262px);
}

.profileActionBar{
    padding-top: 2px;
}

.profileActionBar .individualNavigation{
    grid-template-columns: auto 1fr auto 1fr auto;
}

.reportProfile .unselected{
    background-color: whitesmoke;
}

.reportSheetRowSelected{
    background-color: #e0f2f1 !important;
    font-weight: 500;
    border-top: solid 2px #26a69a !important;
    border-bottom: solid 2px #26a69a !important;
    color: #26a69a !important;
}

.greenLink{
    color:#004d40
}

