@media all and (max-width: 768px){
    body{
        overflow-y: auto;
        overflow-x: hidden;
        min-width: unset;
        height: auto;
    }
    
    #root{
        grid-auto-rows: auto;
        height: auto;
        min-width: unset;
    }

    .App{
        grid-auto-rows: 64px auto;
        overflow-y: auto;
        height: auto;
    }

    .loginBlock{
        width:calc(100% - 16px);
    }

    .overlay{
        position:fixed;
    }

    .overlay > .policyScroller, .overlay > .notifications{
        width:90%;
        height:90%;
    }

    .overlay > .settings{
        position:relative;
        top:unset;
        right: unset;
    }
    
    .overlay .moduleBlock{
        width: calc(100% - 16px);
    }

    .appHeader{
        display: flex;
        grid-template-columns: 64px 1fr 128px;
        position:fixed;
        top:0;
        height:64px;
    }

    .appHeader .policyNav{
        width: 100%;
        grid-template-columns: 1fr;
    }

    .appHeader .tabs > div{
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .appHeader .logo img{
        width: 48px;
        height:48px;
    }

    .appHeader .accountDropdown > div {
        width: 220px;
    }

    .tab{
        background-color: white;
    }

    .tab .prompt {
        grid-template-columns: 1fr;
    }

    .tab .prompt .icon svg{
        width: 32px;
        height: 32px;
    }

    .tab .alert{
        top:calc(50% + 2px);
        left:calc(50% + 28px);
        transform:translate(-50%, -50%);
    }

    .lifeTracPortal{
        grid-template-columns: 1fr;
        grid-template-rows: 48px auto;
        height: auto;
        border-radius: 0;
        width:100%;
    }
    
    .activity.dashboard .dashboardHub{
        grid-template-columns: 1fr;
        grid-template-rows: unset;
        gap:8px;
    }

    .dashboard .left .top.section .details{
        grid-template-rows: min-content 1fr;
    }

    .dashboard .left .top.section .details .breakdown{
         /* display: unset;
        height: max-content;*/
        display: grid;
        height: max-content;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
    }

    .activity.messages{
        height: auto;
        overflow: auto;
        grid-template-rows: repeat(2, 48px) auto 48px;
    }
    .activity.documents{
        grid-template-rows: 48px auto;
        border-radius: 0px !important;
    }

    .activity.timeline{
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        grid-template-rows:48px minmax(84px, auto) auto;
    }

    .lifeTracPortal .tabs2{
        position: fixed;
        height: 48px;
        top: 64px;
        background-color: white;
        z-index: 1;
        grid-template-rows: 1fr;
        box-shadow: 0 0 8px gray;
    }

    .lifeTracPortal .tabs2 > div:nth-child(1){
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 100%;
        z-index: 2;
    }

    .lifeTracPortal .tabs2 > div:nth-child(2){
        position:absolute;
        height:4px;
        bottom:1px;
        box-shadow: 0 0 8px gray;
        z-index: 0;
    }

    .lifeTracPortal .tabs3{
        position: fixed;
        height: 48px;
        top: 64px;
        background-color: white;
        z-index: 1;
        grid-template-rows: 1fr;
        box-shadow: 0 0 8px gray;
    }

    .lifeTracPortal .tabs3 > div:nth-child(1){
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 100%;
        z-index: 2;
    }

    .lifeTracPortal .tabs3 > div:nth-child(2){
        position:absolute;
        height:4px;
        bottom:1px;
        box-shadow: 0 0 8px gray;
        z-index: 0;
    }

    .lifeTracPortal .tabs4{
        position: fixed;
        height: 48px;
        top: 64px;
        background-color: white;
        z-index: 1;
        grid-template-rows: 1fr;
        box-shadow: 0 0 8px gray;
    }

    .lifeTracPortal .tabs4 > div:nth-child(1){
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 100%;
        z-index: 2;
    }

    .lifeTracPortal .tabs4 > div:nth-child(2){
        position:absolute;
        height:4px;
        bottom:1px;
        box-shadow: 0 0 8px gray;
        z-index: 0;
    }

    .policyScroller{
        width:80%;
        height:80%;
    }
    
    .policyForm .form .quad{
        grid-template-columns: repeat(2, auto);
    }

    .policyProgressionBlock .progressBar, .policyProgressionBlock .stages{
        grid-template-columns:16px 48px 1fr 48px 1fr 48px 1fr 48px 1fr 48px 16px;
        gap:4px;
    }

    .overlay .body{
        overflow-y: auto;
    }

    .policyProgressionBlock.alt .progressBar > div:not(.data){
        height:42px;
        width:42px;
    }
    .policyProgressionBlock.alt .progressBar > div.data{
        border:none;
        height:4px;
    }
    .policyProgressionBlock.alt .progressBar, .policyProgressionBlock.alt .stages{
        grid-template-columns: 8px 42px 1fr 42px 1fr 42px 1fr 42px 1fr 42px 8px;
    }

    .messageBoardBottomBar{
        position: fixed;
    }

    .details .content .policyForm{
        grid-template-rows: minmax(36px, auto) minmax(132px, auto) 1fr;
    }
    .documents .content{
        border:none;
    }

    .contactBlock .header{
        grid-template-columns: 36px 1fr;
        grid-template-rows: 36px;
    }
    
    .contactBar{
        grid-template-columns: repeat(3, 1fr);
        color: dimgray;
        font-size: 20px;
        height: 56px;
    }

    .contactBar > div:first-of-type{
        grid-template-columns: 56px 1fr;
    }

    .contactBar .option.contactInfo{
        grid-template-columns: 1fr;
    }

    .contactBar svg{
        width: 48px !important;
        height:48px !important;
    }

    .profilePic img{
        width:56px;
        height:auto;
        border-radius: 50%;
        overflow: hidden;
        background-color: whitesmoke;
    }

    .contactBar .option.contactInfo:hover{
        background-color: transparent;
    }

    .contactBar .option.contactInfo:hover > div{
        background-color: #e3f2fd;
    }

    .contactBar .contactInfo > div{
        border-radius: 50%;
    }

    .contactBar .option.contactInfo > div{
        width: 64px;
        height: 64px;
    }

    .contactBlock, .bidBlock{
        grid-template-rows:minmax(36px, auto) minmax(48px, auto);
    }
    .bidBlock{
        grid-template-rows:minmax(36px, auto);
    }

    .track .progressTrack > .category, .track .progressTrack > .header{
        gap:0;
        grid-template-columns: 24px 1fr 8px;
    }
    
    .overlay .listBoard .list .row .data{
        grid-template-columns: 1fr .5fr 1fr;
        gap:4px;
        padding:0 4px;
    }
    .overlay .listBoard .columnLabels .labels{
        grid-template-columns: repeat(3, 1fr)
    }

    .overlay .notifications .listBoard .columnLabels .labels{
        grid-template-columns: repeat(3, 1fr);
    }
    .overlay .notifications .listBoard .list .row .data{
        grid-template-columns: repeat(3, 1fr);
    }
    .overlay .notifications .listBoard .list .row .data > div.wIcon{
        grid-template-columns: 32px 1fr;
    }
    .preferences .trackBar{
        grid-template-columns: auto repeat(4, 1fr);
        gap:4px;
    }
    iframe{
        height: auto;
        overflow: hidden;
        min-height: 100%;;
    }

    .unsubscribeBoard .form{
        width: 100%;
        height: 100%;
    }

    .unsubscribeBoard .container .prompt{
        align-items: center;
    }

    .unsubscribeBoard .container .prompt .header{
        font-size: 36px;
    }

    .unsubscribeBoard .container .prompt div:not(.header){
        font-size: 22px;
    }

    .unsubscribeBoard .container{
        grid-template-rows: minmax(136px, auto) 1fr auto;
        gap:16px;
    }

    .unsubscribeBoard .container .options{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 64px);
    }

    .bidInfo:not(.inactive){
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 1fr;
    }

    .bidInfo .condense{
        grid-template-columns: repeat(2, 1fr);
        gap:8px;
    }
    .bidInfo .section{
        grid-template-rows: auto 8px auto;
    }

    .chartData{
        width:100%;
        height: auto;
        min-height:100%;
        grid-template-rows: 1fr 96px;
        grid-template-columns: 1fr;
    }

    .pieChartSummary {
        background-color: #F3F4F6;
        border: solid 1px gainsboro;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: unset;
    }

    .individualProfile .selectedProfile .rowData{
        grid-template-columns: repeat(3, auto);
    }
    .individualProfile .selectedProfile .row .inputField{
        width: auto;
    }
    .individualProfile .selectedProfile .row .inputField input{
        width: 100%;
    }
    .individualProfile .selectedProfile .rowData.important{
        grid-template-columns: repeat(2, 1fr);
    }
    .individualProfile .selectedProfile .rowData.location{
        grid-template-columns: repeat(3, 1fr);
    }
    .individualProfile .selectedProfile .rowData.death{
        grid-template-columns: repeat(2, 1fr);
    }

    .dashboard .left.panel{
        gap:8px;
        grid-template-rows: auto auto;
    }

    .dashboard .left .bottom.section{
        height: fit-content;
    }

    .homePagePie{
        z-index: 0;
    }

    .pieChartSummary .block .icon svg{
        width: 24px;
        height: 24px;   
    }

    .summaryInfoBlock{
        grid-template-columns: auto 1fr;
    }

    .reportingHub{
        grid-template-rows: 56px 48px 1fr;
    }

    .reportingHub .reportBuilder{
        max-height: unset;
        grid-template-columns: 1fr;
        padding-right:8px;
    }

    .reportingNavBar .viewBar{
        grid-template-columns: repeat(2, 1fr);
    }

    .reportingHub .criterionBlock.create{
        grid-template-columns: 48px 1fr 48px;
    }

    .reportingHub .reportBuilder .detailBar{
        grid-template-columns: auto 1fr;
    }

    .reportingHub .actionBar.list{
        grid-template-columns:1fr ;
    }

    .reportingHub .actionBar.report{
        grid-template-columns:48px 1fr 48px;
        padding-right: 8px;
    }

    .reportingHub .actionBar.report.generated{
        grid-template-columns: auto 1fr auto;
    }

    .reportingHub .actionBar.generated .btnOptions{
        grid-template-columns: unset;
    }

    .reportingHub .actionBar .btnOptions .btnWIcon{
        width: fit-content;
        padding-left:8px;
    }

    .reportingHub .actionBar.report.searching{
        grid-template-columns: 1fr 48px;
        padding-right:8px;
    }

    .reportingHub .fullActionBar .reportSheetIndividualTitle{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }

    .researchHubContent{
        width: 70%;
    }

    .floatingDetailBar{
        position: fixed;
        bottom:8px;
        background-color: white;
        width: calc(100% - 16px);
        height: 56px;
        left:8px;
        box-shadow: 0 0 8px gray;
        grid-template-columns: auto 1fr;
    }
    .individualProfile .selectedProfile .body{
        grid-template-columns: unset;
        grid-template-rows: repeat(2, auto);
    }
    .individualProfile .selectedProfile .obituaries{
        grid-template-rows: repeat(2, auto);
        grid-template-columns: unset;
    }
    .adminBoard .settingsContainer .manageAccountsContent .rightPanel .manageHeaderRow{
        grid-template-columns: 0px 1fr 42px 42px;
    }
    .adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .trackBar{
        grid-template-columns: 64px repeat(2, 1fr) 32px;
    }

    .researchHubContent .researchHubInputs .inputRow1{
        grid-template-columns: 0px 1fr 0px 1fr 0px 1fr 0px;
    }
    
    .researchHubContent .researchHubInputs .inputRow2{
        grid-template-columns: 0px auto .8fr 0px 1fr 0px 1fr 0px;
    }
    
    .researchHubContent .researchHubInputs .obitInputRow2{
        padding:0px;
        gap:2px;
        grid-template-columns: 0px auto .8fr 0px 1fr 0px 1fr 0px 1fr 0px;
    }

    .researchHubInputs .dobField {
        min-width: 55px;
    }

    .adminBoard .settingsContainer .manageAccountsContent{
        grid-template-columns: 1fr 1.5fr;
    }

    .adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .settingsWidgetContent {
        overflow: auto;
        max-height: 520px;
    }

    .defaultForm .row .rowData {
        grid-template-columns: repeat(2, 1fr);
        min-width: 240px;
    }

    .profileScroll{
        max-height: 400px;
        overflow: auto;
    }

    .rightPanel .actionBar{
        grid-template-columns: 1fr 36px 1fr;
    }


    .viewBar{
        width:min-content;
        height:min-content;
        min-height: 0;
        min-width: 0;
        display: block;
        position: relative;
        border:solid 2px #26a69a;
        border-radius: 36px;
        overflow: hidden;
        padding:4px;
        gap:8px;
      }
      
      .viewBar .viewBarBtn{
        margin: 0 auto;
        min-height: 0;
        min-width: 0;
        display: block;
        height: min-content;
        width: max-content;
        line-height: 1;
        color:dimgray;
        border-radius: 36px;
        padding:4px;
        border:solid 2px transparent;
      }
      
      .viewBar .viewBarBtn:not(.active):hover{
        background-color:#e0f2f1;
        border:solid 2px #26a69a;
        color:#26a69a;
      }
      
      .viewBar .viewBarBtn.active{
        background-color: #26a69a;
        font-weight: 500;
        color:white;
      }
      
      .viewBar .viewBarBtn .prompt{
        grid-template-columns: 1fr auto;
        gap:8px;
      }
}