.nestedOverlay .removeUser{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 35%;
    background-color: white;
    border: 2px solid gray;
    z-index: 10;
    transform: translate(-50%, -50%);
    /* grid-template-rows: 40px 200px 1fr 2fr; */
    grid-template-columns: 1fr;
}

.nestedOverlay .deleteReport{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 35%;
    background-color: white;
    border: 2px solid gray;
    z-index: 10;
    transform: translate(-50%, -50%);
    /* grid-template-rows: 40px 200px 1fr 2fr; */
    grid-template-columns: 1fr;
}

.nestedOverlay .generateKey{
    position: absolute;
    top: 50%;
    left: 60%;
    width: 35%;
    height: 35%;
    background-color: white;
    border: 2px solid gray;
    z-index: 10;
    transform: translate(-50%, -50%);
    grid-template-rows: 40px 200px 1fr 2fr;
    grid-template-columns: 1fr;
}

.nestedOverlay .disableIndividual{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 35%;
    background-color: white;
    border: 2px solid gray;
    z-index: 10;
    transform: translate(-50%, -50%);
    grid-template-rows: 40px 200px 1fr 2fr;
    grid-template-columns: 1fr;
}