/* Upload History Overlay */
.historyContainer{
    /* grid-template-rows: repeat(auto,64px); */
    width: auto;
    height: auto;
    border: 2px solid lightgray;
    box-shadow: outset;
    margin: 36px;
    margin-top: 12px;
    border-radius: 5px;
    overflow: auto;
}

.successHistoryContainer{
    border: 2px solid lightgrey;
    grid-template-rows: 48px auto;
    max-height: 469px;
    overflow: auto;
    background-color: white;
}

.historyEmptyContainer{
    grid-template-rows: 64px 1fr;
    width: auto;
    height: auto;
    border: 2px solid lightgray;
    box-shadow: outset;
    margin: 36px;
    border-radius: 5px;
}

.historyEmptyContent{
    grid-template-rows: 1fr auto auto 1fr;
    background-color: rgb(240, 240, 240); 
    color: gray;
    font-weight: 500;
    font-size: 30px;
}

.historyHeaders{
    height: 64px;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-bottom: 2px solid #004d40;
    background-color: white;  
    white-space: nowrap;
    position: sticky;
    top: 0; 
    z-index: 1;
}

.singleHistoryHeaders{
    height: 64px;
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid #004d40;
    background-color: white;  
    white-space: nowrap;
    position: sticky;
    top: 0; 
    z-index: 1;
}

.historySuccessHeaders{
    height: 48px;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-bottom: 2px solid #004d40;
    background-color: white;  
    position: sticky;
    top: 0; 
    z-index: 1;
}

.historyCount{
    position: sticky;
    z-index: 1;
    bottom: -1px; 
    border-top: 2px solid #004d40;
    background-color: white;  
}

.uploadTile{
    grid-template-columns: 2fr 1fr 1fr 1fr;
    height: 64px;
    /* border: 2px solid gray; */
    border-radius: 5px;
}

.singleUploadTile{
    grid-template-columns: 1fr 1fr;
    height: 64px;
    /* border: 2px solid gray; */
    border-radius: 5px;
}


.fileGrid{
    grid-template-columns: 1fr 36px;
}

.tileShade1{
    background-color: white;
}
.tileShade2{
    background-color: #e0f2f1;
}


/* Upload Success Overlay */

.successContainer{
    margin: 36px;
    margin-top: 12px;
    margin-bottom: 12px;
    grid-template-rows: 36px 1fr;
}

.successTabs{
    grid-template-columns: 3fr 1fr 1fr;
}

.successTab{
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin: 0 2px;
    margin-bottom: 2px;
    transition: background-color 0.3s;
    white-space: nowrap;
}

.successTab:hover {
    background-color: #e2e2e2; 
}

.successTabSelected {
    background-color: #d3d3d3; 
    color: #666; 
    cursor: default;
    pointer-events: none;
}

.successInfoContainer{
    border: 2px solid lightgrey;
    grid-template-rows: 48px auto;
    padding: 30px;
    max-height: 469px;
    overflow: auto;
    background-color: white;
}

.successMessage{
    font-size: 18px;
}

.statsDisplay{
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap:8px;
}

.statRow{
    grid-template-columns: 1fr 1fr;
    border: 2px solid gray;
    padding-left: 100px;
    background-color: whitesmoke; /* #e0f2f1; */   
}

/* Upload Failure Overlay */

.uploadFailure{
    padding: 8px;
    margin: 36px;
    margin-top: 12px;
    margin-bottom: 12px;
    grid-template-rows: 1fr;
}

.failureContainer{
    border: 2px solid #f44336;
    padding: 30px;
    max-height: 469px;
    overflow: auto;
    background-color: #ffebee;
    gap: 24px;
}

.removeFileContainer{
    border: 2px solid gray;
    padding: 50px;
    overflow: auto;
    background-color: whitesmoke;
    gap: 24px;
}

/* SFTP Success Overlay */
.SFTPSuccess{
    padding: 8px;
    margin: 36px;
    margin-top: 12px;
    margin-bottom: 12px;
    grid-template-rows: 1fr;
}

.SFTPSuccessContainer{
    border: 2px solid #a1cd6d;
    padding: 30px;
    max-height: 469px;
    overflow: auto;
    background-color: #f1f8e9;
    gap: 24px;
}

/* SFTP Loading Overlay */
.sftpLoadingContainer{
    grid-template-rows: 1fr auto auto 1fr;
}

.sftpLoadingContainer .progress {
    --progress: 0%;
    
    width: 500px;
    height: 50px;
    /* margin: 9em auto; */
    border: 1px solid #fff;
    box-shadow: 0 0 10px #aaa;
    box-sizing: border-box; /* Ensures padding and border are included in width */
    padding: 0; /* Remove padding */
}

.sftpLoadingContainer .progress .bar {
    width: var(--progress);
    height: 100%;
    background: linear-gradient(#b5e2e0, #009688, #b5e2e0);
    background-repeat: repeat;
    box-shadow: 0 0 10px 0px #009688;
    animation: 
      shine 4s ease-in infinite,
      end 1s ease-out 1;
    box-sizing: border-box; /* Consistent box-sizing for inner element */
    transition: width 0.3s linear;
}

@property --progress {
    syntax: "<percentage>";
    initial-value: 0%;
    inherits: true;
}

@keyframes shine {
    0% { background-position: 0 0; }
    100% { background-position: 0 50px; }
}

@keyframes end {
    0%, 100% { box-shadow: 0 0 10px 0px #009688; }
    50% { box-shadow: 0 0 15px 5px #009688; }
}



@media all and (max-width: 768px){

    .historyContainer{
        /* grid-template-rows: repeat(auto,64px); */
        width: auto;
        height: auto;
        border: 2px solid lightgray;
        box-shadow: outset;
        margin: 8px;
        margin-top: 12px;
        border-radius: 5px;
        overflow: auto;
    }

    .successContainer{
        margin: 0px;
        margin-top: 12px;
        margin-bottom: 12px;
        grid-template-rows: 36px 1fr;
    }

    .statRow{
        grid-template-columns: 1fr 1fr;
        border: 2px solid gray;
        padding-left: 8px;
        background-color: whitesmoke; /* #e0f2f1; */   
    }

    .removeFileContainer{
        border: 2px solid gray;
        padding: 10px;
        overflow: auto;
        background-color: whitesmoke;
        gap: 24px;
    }

    .uploadFailure{
        padding: 8px;
        margin: 0px;
        margin-top: 12px;
        margin-bottom: 12px;
        grid-template-rows: 1fr;
    }

}