a:visited {
  color:inherit;
}

a{
  color:unset;
}

.nS{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

/* Default Gap */
.dG{
  gap: 8px;
}

/* Center Text for One Line */
.lH{
  line-height: 1;
}

/* Center Center */
.cC{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
  }
  
  /* Center Left */
  .cL{
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    align-content: space-evenly;
    align-items: center;
    text-align: left;
  }

  /* Left Aligned */
  .l{
    text-align: left;
    align-content: space-evenly;
  }

  /* Default Padding ( Each side ) */
  .dP{
    padding:8px;
  }

  /* Start Padding */
  .s{
    padding-left:8px;
  }
  
  /* End Padding */
  .e{
    padding-right:8px;
  }
  
  /* Top Padding */
  .t{
    padding-top:8px;
  }
  
  /* Bottom Padding */
  .b{
    padding-bottom:8px;
  }
  
  /* Center Right */
  .cR{
    display: flex;
    justify-content: flex-end;
    justify-items: flex-end;
    align-content: space-evenly;
    align-items: center;
    text-align: right;
  }
  
  /* Top Center */
  .tC{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: flex-start;
    text-align: center;
  }
  
  .bC{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: flex-end;
    text-align: center;
  }
  .tL{
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    align-content: space-evenly;
    align-items: flex-start;
    text-align: left;
  }

  /* Default Row */
  .dR{
    height:36px;
    width:calc(100% - 16px);
    line-height:36px;
    margin: auto;
  }
  .dR.iB{
    background-color:#EFEFEF;
  }
  
  /* Default Border Radius */
  .bR{
    border-radius:5px;
  }
  
  /* Default Border */
  .dB{
    border:solid 1px gainsboro;
    box-sizing: content-box;
  }
  
  /* Active Border */
  .aB{
    border:solid 2px #c8e6c9;
    box-sizing: content-box;
  }
  
  /* Inactive Border */
  .iB{
    border:solid 2px gainsboro;
    box-sizing: content-box;
  }
  
  /* Grayed Out */
  .gO{
    color:gainsboro;
    cursor:default !important;
  }
  .gO *{
    color:gainsboro;
    cursor:default !important;
  }
  
  /* Grid */
  .g{
    display:grid;
  }
  
  /* Grid Column Wrap */
  .gCW{
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: initial !important;
    line-height: initial !important;
  }
  .gCW:not(.alt) div{
    height: 100%;
    display: inline-grid;
    vertical-align: middle;
  }
  /* Allow for objects, such as dropdown, to overflow */
  .gCW.alt{
    overflow: unset;
  }

  /* Grid Columns */
  .gC1{
    grid-column: 1;
  }
  .gC2{
    grid-column: 2;
  }
  .gC3{
    grid-column: 3;
  }
  .gC4{
    grid-column: 4;
  }
  .gC5{
    grid-column: 5;
  }
  .gC6{
    grid-column: 6;
  }
  .gC7{
    grid-column: 7;
  }
  .gC8{
    grid-column: 8;
  }
  .gC9{
    grid-column: 9;
  }
  .gC10{
    grid-column: 10;
  }
  .gC11{
    grid-column: 11;
  }
  .gC12{
    grid-column: 12;
  }
  /* Grid Rows */
  .gR1{
    grid-row: 1; 
  }
  .gR2{
    grid-row: 2; 
  }
  .gR3{
    grid-row: 3; 
  }
  .gR4{
    grid-row: 4; 
  }
  .gR5{
    grid-row: 5; 
  }
  .gR6{
    grid-row: 6; 
  }
  .gR7{
    grid-row: 7; 
  }
  .gR8{
    grid-row: 8; 
  }
  .gR9{
    grid-row: 9; 
  }
  .gR10{
    grid-row: 10; 
  }
  .gR11{
    grid-row: 11; 
  }
  .gR12{
    grid-row: 12; 
  }
  /* Fill */
  .f{
    width:100%;
    height:100%;
  }
  
  .fMP{
    width:calc(100% - 16px);
    height:calc(100% - 16px);
  }
  /* Fill Row */
  .fR{
    grid-template-rows: 100%;
  }

  /* Fill Column */
  .fC{
    grid-template-columns: 100%;
  }

  .divider{
    margin:auto;
    width:80%;
    height:2px;
    background-color: #E2E2E2;
    border-radius: 5px;
  }
  
  .vDivider{
    margin:auto;
    height:80%;
    width:2px;
    background-color: #E2E2E2;
    border-radius: 5px;
  }

  .hDivider{
    height:2px;
    width:80%;
    background-color: #E2E2E2;
    border-radius: 5px;
  }

  .link{
    font-weight:500;
    color:#42a5f5;
  }
  .link:hover{
    color:#1769aa
  }
  
  .loading {
    -webkit-animation: loadingAnimation ease;
    animation: loadingAnimation ease;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    color: gray!important;
  }

  @keyframes loadingAnimation{
    0% {
      background-color: #EFEFEF;
    }
  
    25% {
      background-color: #E2E2E2;
    }
  
    50% {
      background-color: #CCC;
    }

    75% {
      background-color: #E2E2E2;
    }
    100% {
      background-color: #EFEFEF;
    }
  }

  @keyframes loadingAnimationDarkMode{
    0% {
      background-color: #212121;
    }
  
    50% {
      background-color: #5E5E5E;
    }
  
    100% {
      background-color: #212121;
    }
  }

  .loadingBlock{
    background-color: #F3F4F6;
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    box-shadow: 0 0 5px #ccc;
  }

  .loading .f svg{
    color: gray;
  }

  .loadingBlock svg{
    color:#00897b;
  }
  .loadingBlockMessage{
    width: auto;
    height: auto;
    max-height: 46px;
    min-width: 100%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 100%;
    font-size: 32px;
    font-weight: 700;
    color: gray;
  }

  .btn.circle{
    border-radius: 50%;
    aspect-ratio: 1/1;
    border:solid 2px #26a69a;
    color:#004d40;
    width: 36px;
    height: 36px;
  }
  .btn.circle:hover{
    background-color: #e0f2f1;
    border-color: #004d40;
  }
  .btn.loading{
    background-color: #00897b !important;
    color:white !important;
  }
  .btn.loading span{
    color:white !important;
    width:28px !important;
    height:28px !important;
  }
  .btn.loading:hover{
    background-color: white !important;
    color:#00897b !important;
  }
  .btn.loading:hover svg{
    color:#00897b !important;
  }

  .btn.more{
    height: 100%;
    color: dimgray;
  }

  .btnWIcon{
    border:solid 2px #26a69a;
    color:#004d40;
    grid-template-columns: 1fr 24px;
  }
  
  .btnWIcon:hover{
    background-color: #e0f2f1;
    border-color: #004d40;
  }

  .btnWIcon.filled{
    background-color: #e0f2f1;
    border-color: #004d40;
    border-style: dashed;
  }

  .btnWIcon.error{
    color:#b71c1c;
    border-color: #f44336;
  }
  .btnWIcon.error:hover{
    background-color:#ffebee;
  }

  .cashString{
    color:#388e3c;
    font-weight: 500;
  }
  
  /* Pointer Cursor */
  .p{
    cursor:pointer !important;
  }

  .defaultBtn{
    box-sizing: border-box;
    outline: 2px solid #00897b;
    color: #00897b;
    background-color: inherit;
    font-weight: 400;
    border-radius: 5px;
    display: inline-grid;
    cursor:pointer;
  }

  .defaultBtn:hover{
    background-color: #00897b;
    color:white;
  }

  .defaultBtn.icon{
    grid-template-columns: calc(100% - 28px) 28px;
  }

  .defaultBtn.alt{
    background-color: #26a69a;
    color:white;
    height: 32px;
    line-height: 32px;
    width:150px;
    outline:none;
  }

  .defaultBtn.alt:hover{
    background-color: white;
    color:#26a69a;
    outline: 2px solid #26a69a;
  }

  .default.btn{
    outline:solid 2px #26a69a;
    color:#26a69a;
    font-weight:500;
    background-color: white;
    grid-template-columns: 1fr 32px;
  }

  .default.btn:hover{
    color:white;
    background-color:#26a69a;
  }

  .btn.inactive, .btn.inactive:hover, .btnWIcon.inactive, .btnWIcon.inactive:hover{
    background-color: #EFEFEF !important;
    color:gray !important;
    border: 2px solid #E2E2E2 !important;
    cursor:default !important;
    box-shadow: none !important;
  }

  .default.btn.loading span {
    height: 24px !important;
    width: 24px !important;
  }

  .btn.close{
    border-radius: 50%;
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    color:gray;
  }
  .btn.back{
    border-radius: 50%;
    color:gray;
    width:48px;
    height:48px;
  }
  .btn.close:hover{
    background-color: whitesmoke;
    color:dimgray;
  }

  .progress span, .progress svg{
    color:inherit !important;
    width:24px;
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .progress.alt span, .progress.alt svg{
    width:18px;
  }

  /* Overflow */
  .oH{
    overflow: hidden;
  }

  .oA{
    overflow: auto;
  }
  
  /* Positions */
  /* Relative */
  .pR{
    position:relative;
  }

  .pA{
    position:absolute;
  }


  .bold{
    font-weight:500;
  }

  .thin{
    font-weight: 400;
  }

  .italics{
    font-style: italic;
  }

  .overlay{
    position:fixed;
    top:0;
    left:0;
    z-index: 999;
    width:100%;
    height:100%;
  }

  .overlay .moduleBlock.default{
    background-color: white;
    border-radius:5px;
    grid-template-rows: 48px 1fr;
  }
  .overlay .moduleBlock.inline{
    background-color: white;
    border-radius:5px;
  }
  .overlay .moduleBlock.action{
    background-color: white;
    border-radius:5px;
    grid-template-rows: 48px 1fr 48px;
  }

  .overlay .moduleBlock.wAction{
    grid-template-rows: 48px 1fr 48px;
  }

  .overlay .moduleBlock.wAction .format{
    grid-template-rows: 100%;
  }

  .overlay .moduleBlock{
    background-color: white;
    border-radius:5px;
    grid-template-rows: 48px 1fr;
  }

  .overlay .moduleBlock > .header{
    height:48px;
    grid-template-columns: 48px 1fr 48px;
    color:dimgray;
  }

  .overlayBlur{
    filter: blur(2px);
    /* opacity: 0.5; */
  }
  
  .nestedOverlay{
    position:fixed;
    top:0;
    left:0;
    z-index: 1000;
    width:100%;
    height:100%;
  }
  .nestedOverlay .moduleBlock.default{
    background-color: white;
    border-radius:5px;
    grid-template-rows: 48px 1fr;
  }
  .nestedOverlay .moduleBlock.inline{
    background-color: white;
    border-radius:5px;
  }
  .nestedOverlay .moduleBlock.action{
    background-color: white;
    border-radius:5px;
    grid-template-rows: 48px 1fr 48px;
  }

  .nestedOverlay .moduleBlock.wAction{
    grid-template-rows: 48px 1fr 48px;
  }

  .nestedOverlay .moduleBlock.wAction .format{
    grid-template-rows: 100%;
  }

  .nestedOverlay .moduleBlock{
    background-color: white;
    border-radius:5px;
    grid-template-rows: 48px 1fr;
  }

  .nestedOverlay .moduleBlock > .header{
    height:48px;
    grid-template-columns: 48px 1fr 48px;
    color:dimgray;
  }

  .contentBox{
    letter-spacing: inherit;
    border: solid 1px #EFEFEF;
    box-sizing: border-box;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    animation-duration: 10ms;
    resize: none;
    font-family: inherit;
    position: absolute;
    padding:8px;
    height: 100%;
    width:calc(100% - 16px);
  }

  .switchViewBtn.alert{
    grid-template-columns: repeat(3, 1fr);
  }

  .switchViewBtn .option{
    display: inline-block; /* Set display to inline-block */
    width: 100%; /* Set width to 100% to fill the available space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .source.agent{
    height: 28px;
    color: #f57f17;
    background-color: #FFEFA9;
    grid-template-columns: 36px 1fr;
  }

  .source.broker{
    height: 28px;
    color: #1a237e;
    background-color: #e8eaf6;
    grid-template-columns: 36px 1fr;
  }

  .source.clientDirect{
    height: 28px;
    color: #880e4f;
    background-color: #fce4ec;
    grid-template-columns: 36px 1fr;
  }
  
  .source.other{
    height: 28px;
    color: #e65100;
    background-color: #fff3e0;
    grid-template-columns: 36px 1fr;
  }
  
  .searchBar input{
    height: 32px;
    border-radius: 5px;
    border-style: none;
    background-color: #F3F4F6;
    padding: 8px;
    width: 100%;
    position: relative;
    margin: auto;
    display: block;
    bottom: 0;
    left: 0;
    border: solid 1px gainsboro;
  }

.loader{
    grid-template-rows: 64px 24px;
    color:dimgray;
    font-weight:500;
    font-size: 20px;
}

.loader :first-child{
    grid-template-columns: repeat(3, 1fr);
    gap:8px;
}

.loader svg{
    height:48px;
    width: 48px;
    color:#00897b;
}

.loader .icon:nth-child(1) {
    animation: icon-animation 1.5s linear infinite;
    animation-delay: 0s;
}

.loader .icon:nth-child(2) {
  animation: icon-animation 1.5s linear infinite;
  animation-delay: 0.5s;
}

.loader .icon:nth-child(3) {
  animation: icon-animation 1.5s linear infinite;
  animation-delay: 1s;
}

@keyframes icon-animation {
  0%, 33.33% {
    transform: translateY(0);
  }
  16.67% {
    transform: translateY(-20px);
  }
}

.inlineActionBar{
  grid-template-columns: auto 1fr auto;
  gap:8px;
  color:dimgray;
}

.bidsPage .switchViewBtnContainerWrap{
  grid-template-columns: 1fr 48px;
  gap:8px;
}

.switchViewBtnContainerWrap .moreBtn{
  color:dimgray;
  border-radius: 50%;
  width:32px;
  height:32px;
}
.switchViewBtnContainerWrap .moreBtn:hover{
  background-color: #EFEFEF;
  color:gray;;
}

.active:not(.failed) > .checkStatus{
  color:#43a047;
}

/* Transparent Overflow Scrollbar */
.tO::-webkit-scrollbar-track {
  background-color: initial;
}

.tO::-webkit-scrollbar-thumb{
  border-color:transparent;
}

/* Track */
::-webkit-scrollbar {
  width: 18px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: dimgray;
    background-clip: padding-box;
    border: 4px solid #fff;
    border-radius: 18px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

.mA{
  margin:auto;
}

.empty{
  font-size:24px;
  font-weight: 500;
  color:dimgray;
  transition: 0ms !important;
}

.empty svg{
  width: 48px;
  height: 48px;
}

.loginReqs{
  color:dimgray;
  font-size:22px;
  font-weight: 100;
}

.toggle{
  width:96px;
  height: 48px;
  border-radius: 48px;
  border:solid 2px gray;
  background-color: #E2E2E2;
  transition: 500ms !important;
}

.toggle .status{
  width:32px;
  height: 32px;
  border-radius: 50%;
  border:solid 2px gray;
  background-color: #EFEFEF;
  transition: 500ms !important;
}

.toggle.loading .status{
  border-style: dashed;
}

.toggle .status *{
  width: 24px !important;
  height: 24px !important;
  color:gray;
}

.toggle.active{
  border-color:#00897b;
  background-color: #26a69a;
}

.toggle.active .status{
  border-color:#00897b;
  background-color: #e0f2f1;
}

.tabs > div.tabCount1 {
  grid-template-columns: 1fr;
}

.tabs > div.tabCount2 {
  grid-template-columns: repeat(2, 1fr);
}

.tabs > div.tabCount3 {
  grid-template-columns: repeat(3, 1fr);
}

.tabs > div.tabCount4 {
  grid-template-columns: repeat(4, 1fr);
}

.tabs > div.tabCount5 {
  grid-template-columns: repeat(5, 1fr);
}

.tabs > div.tabCount6 {
  grid-template-columns: repeat(6, 1fr);
}

.tab {
  font-size: 18px;
  grid-template-rows: 4px 1fr 4px;
  position: relative;
}

.tab.active .status {
  /* background-color: #26a69a; */
  background-color: #4c8273;
}

/* .tab.active * :not(.alert) {
  color: inherit;
} */

.tab.offline{
  cursor:default;
}

.tab.active:not(.offline) * {
  /* color: #26a69a; */
  color: #4c8273;
  font-weight: 500;
}

.tab:not(.alt):hover {
  background-color: #e0f2f1;
}

.tab:not(.alt, .active):hover .prompt{
  color:#00897b;
}

.tab:not(.active):hover .status {
  background-color: #00897b;
}

/* Inactive Tab */
.tab.inactive * {
  color: #e2e2e2;
}

.tab .prompt {
  color: dimgray;
  grid-template-columns: 1fr minmax(auto,48px);
}

.tab.inactive * :not(.alert) {
  color: inherit;
}

.tab.inactive * {
  color: #e2e2e2;
}

.tab.inactive:hover{
  background-color: #EFEFEF;
  color:dimgray;
}

.tab.inactive:not(.alt):hover {
  background-color: #F3F4F6;
}

.tab.inactive:hover .prompt {
  color: gray;
}

.tab.inactive:hover .status {
  background-color: gray;
}

.tabs .icon svg {
  height: 32px;
  width: 32px;
}

.viewBar{
  width:min-content;
  height:min-content;
  min-height: 0;
  min-width: 0;
  display: grid;
  position: relative;
  border:solid 2px #26a69a;
  border-radius: 36px;
  overflow: hidden;
  padding:4px;
  gap:8px;
}

.viewBar .viewBarBtn{
  margin: 0 auto;
  min-height: 0;
  min-width: 0;
  display: block;
  height: min-content;
  width: max-content;
  line-height: 1;
  color:dimgray;
  border-radius: 36px;
  padding:4px;
  border:solid 2px transparent;
}

.viewBar .viewBarBtn:not(.active):hover{
  background-color:#e0f2f1;
  border:solid 2px #26a69a;
  color:#26a69a;
}

.viewBar .viewBarBtn.active{
  background-color: #26a69a;
  font-weight: 500;
  color:white;
}

.viewBar .viewBarBtn .prompt{
  grid-template-columns: 1fr auto;
  gap:8px;
}

.btn.dropdown{
  border: solid 2px #004d40;
  height: 36px;
  grid-template-columns: 156px 24px;
  gap: 8px;
  position: relative;
  width: -webkit-fill-available;
  /* min-width: calc(100% - 8px); */
  line-height: 1;
}

.btn.dropdown.flex{
  grid-template-columns: minmax(min-content, 1fr) 24px;
}

.stateDropdown .btn.dropdown{
  border: solid 2px #009688;
  color:dimgray;
  height: 32px;
  grid-template-columns: minmax(min-content, 1fr) 24px;
  gap: 8px;
  position: relative;
  width: -webkit-fill-available;
  /* min-width: calc(100% - 8px); */
  line-height: 1;
}

.dropdown.menu{
  position: absolute;
  top: calc(100% + 8px);
  grid-template-columns: repeat(1, minmax(50px, 1fr));
  gap: 8px;
  border: solid 2px #004d40;
  z-index: 1;
  max-height: 300px;
  width: inherit;
  min-width: -webkit-fill-available;
  background-color: white;
  bottom: unset;
  overflow: auto;
}

.dropdown.menu.inverted{
  position: absolute;
  bottom: calc(100% + 8px);
  grid-template-columns: repeat(1, minmax(50px, 1fr));
  gap: 8px;
  border: solid 2px #004d40;
  top: unset;
  z-index: 1;
  max-height: 300px;
  width: 100%;
  background-color: white;
  overflow: auto;
}

.dropdown.menu .option {
  border: solid 2px #004d40;
  width: auto;
  height: 48px;
  grid-template-columns: 1fr;
  gap: 8px;
  pointer-events: all;
  color: #004d40;
}

.dropdown.menu .option.selected{
  background-color: #b2dfdb;
}

.dropdown.menu .option:hover, .btn.dropdown:not(.opened):hover, .btn.dropdown.opened.hovering:hover {
  background-color: #e0f2f1;
}

.multilineInput{
  border: solid 2px #E2E2E2;
  border-radius: 5px;
  font:inherit;
  resize:none;
  width: -webkit-fill-available;
  outline:none;
}

.multilineInput:focus, .multilineInput:active{
  border:solid 2px #00897b !important;
}

.defaultForm{
  gap: 32px;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  height: -webkit-min-content;
  height: min-content;
  width: 100%;
}

.defaultForm .row{
  gap: 8px;
  grid-template-rows: repeat(2,auto);
}

.defaultForm .row .header:first-of-type{
  color: dimgray;
  grid-template-columns: auto auto auto auto;
}
.defaultForm .row .header:not(:first-of-type){
  padding-left:16px;
}

.defaultForm .row .rowData{
  gap: 8px;
  grid-template-columns: repeat(3,1fr);
}

.defaultForm .rowData.preferences{
  grid-template-columns: unset
}

.percentageBar{
  grid-template-columns: 64px 1fr 64px;
}
.percentageBar:not(.inactive, .idle) .bar{
  border: solid 2px #4c8273;
  height: 22px;
  border-radius: 22px;
}

.percentageBar.inactive .bar, .percentageBar.idle .bar{
  border: solid 2px #E2E2E2;
  height: 22px;
  border-radius: 22px;
}

.percentageBar:not(.idle, .inactive) .bar .progress{
  background-color: #4c8273;
  height: 100%;
  position: relative;
  padding-right: 4px;
}

.percentageBar:not(.idle, .inactive) .bar .progress .point {
  color: white;
  line-height: 1;
}