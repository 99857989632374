.redCell{
    background-color: #ffcdd2;
    color: #b71c1c;
    cursor: pointer;
    border: 2px solid transparent;
}

.redCellHover:hover{
    background-color: #fcb2b9;
    color: #b71c1c;
    font-weight: 500;
    border: 2px solid #b71c1c;
    cursor: pointer;
}

.greenCell{
    background-color: #C8E6C9;
    color: #2E7D32;
    font-weight: 500;
    border: 2px solid #2E7D32;
    cursor: pointer;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;   
}

.greenCellHover:hover {
    background-color: #c1e4c2;
}

.redFont{
    color:red;
}

.greenFont{
    color:#66bb6a;
}

.redCell input{
    border:none;
    outline:none;
    background-color: inherit;
    font-weight: 500;
    color: #b71c1c;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
}

.greenCell input{
    border:none;
    outline:none;
    background-color: inherit;
    font-weight: 500;
    color: #2E7D32;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
}

.fileShareBox .reportBuilder .columnHeader{
    height: 50px;
    max-height: 50px;
    min-width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
    border-bottom:solid 2px #E2E2E2;
}

.noPadding{
    padding: 0px;
    border: none;
}

.redBorder > * > *{
    border: 2px solid #b71c1c !important;
}


@keyframes fadeGreenBackgroundColor {
    0% {
      background-color: #2E7D32; 
    }
    100% {
        background-color: #66bb6a;
    }
}

@keyframes fadeRedBackgroundColor {
    0% {
      background-color: #b71c1c; 
    }
    100% {
        background-color: #fcb2b9;
    }
}

.animateGreenCell{
    animation: fadeGreenBackgroundColor .5s ease-in-out;
}

.animateRedCell{
    animation: fadeRedBackgroundColor .5s ease-in-out;
}