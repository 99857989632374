.inputField{
    display:inline-grid;
    text-align: left;
    grid-template-rows: repeat(2, 1fr);
    vertical-align: bottom;
    position: relative;
    height:64px;
    width:100%;
    font-weight:100;
    font-size: 18px;
    gap:0px;
  }
  .inputField.search{
    grid-template-rows: 1fr;
    height: auto;
  }

  .inputField svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    z-index: 0;
  }
  .fieldCouple{
    height:56px;
  }
  
  .subFieldCouple{
    width:47.5%;
    display: inline-block;
  }
  
  div > .subFieldCouple ~ .subFieldCouple{
    float:right;
  }
  
  .fieldCouple .inputField:nth-child(1){
    width:47.5%;
    margin-right:2.5%;
  }
  .fieldCouple .inputField:nth-child(2){
    width:47.5%;
    margin-left:2.5%;
  }
  .inputField input{
    width: 100%;
    height: 32px;
    border-style: none;
    padding: 8px;
    font-size: 18px;
    border: solid 2px transparent;
    border-bottom:solid 2px #EFEFEF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .inputField.passwordContainer{
    height:105px;
  }
  
  .passwordRequirements{
    position: relative;
    border-radius:12px;
    width:100%;
    height:42px;
    line-height: 16px;
    z-index: 2;
    color:gray;
  }
  
  .passwordRequirements.active{
    color:#EF5350;
  }
  
  :not(.search).inputField input.active{
    width: 100%;
    height: 32px;
    border-style: none;
    background-color: white;
    padding: 8px;
    border: solid 2px #009688;
    border-radius: 5px;
    color:dimgray;
  }
  .inputField input.preFace{
    padding-left:15px;
  }
  .inputField input:read-only{
    color:dimgray;
  }

  .inputField input:focus, :not(.search).inputField input:focus{
    color:#004d40;
    background-color: #e0f2f1;
    border-color: #004d40;
    border-width: 2px;
    outline:none;
  }

  /* Single upload page input fields */
  .singleUploadContainer .inputField input:focus, :not(.search).inputField input:focus{
    color:#004d40;
    background-color: white;
    border-color: #004d40;
    border-width: 2px;
    outline:none;
  }
  .singleUploadContainer :not(.search).inputField input.active{
    color:#004d40;
  }

  :not(.search).inputField input:focus{
    border-radius: 5px;
  }

  :not(.search).inputField.inactive input:focus{
    background-color: whitesmoke;
    border-color: gray;
    outline:none;
    color:dimgray;
  }

  .fieldName{
    padding-left:8px;
    font-size:18px;
    font-weight: 400;
    color:gray;
  }
  
  .showPasswordBtn{
    position:absolute;
    border-radius:50%;
    height:24px;
    width:24px;
    right:-28px;
    bottom:4px;
    color:gray;
  }
  .showPasswordBtn:hover{
    cursor:pointer;
  }

  input[type=date]{
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color:gray;
    line-height: 32px;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator{
    width:25px;
    height:25px;
    cursor:pointer;
    color-scheme: default;
    opacity:0;
    z-index: 1;
  }
  
  .datePlaceholder{
    position: absolute;
    right:50px;
    top:26px;
    z-index: 0;
    height:30px;
    width:auto;
    line-height:30px;
    color:gray;
    font-size:14px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .inputFieldContainer{
    position: relative;
  }
  .inputField .dateAge{
    position: absolute;
    right:40px;
    top:25px;
    z-index: 0;
    color:gray;
    font-size:14px;
  }
  .dropdownList{
    position: absolute;
    z-index: 2;
    background-color:white;
    border-radius: 5px;
    -moz-box-shadow: 0 0 8px gray;
    -webkit-box-shadow: 0 0 8px gray;
    box-shadow: 0 0 8px gray;
    overflow: hidden;
    width:95%;
    left:50%;
    top:calc(100% + 4px);
    transform: translate(-50%, 0);
  }
  .dropdownList.invert{
    position: absolute;
    left: 50%;
    top: unset;
    bottom: 36px;
  }
  
  .dropdownListInner{
    max-height:150px;
    overflow-y: auto;
  }
  
  .dropdownListItem{
    display:none;
    color:dimgray;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  
  .dropdownList.active .dropdownListItem{
    display:flex;
  }
  
  .dropdownListItem{
    cursor:pointer;
  }
  
  .dropdownListItem:hover{
    color:white;
    background-color:#00BFFF;
  }
  
  .dropdownListItem p{
    padding-left: 5px;
    line-height: 20px;
  }
  .inputPreface{
    position: absolute;
    left: 5px;
    top: 24px;
    cursor: pointer;
    z-index: 0;
    color: dimgray;
    font-weight: 500;
  }
  
  .inputField.error input{
    border-color:#EF5350;
  }
  
  .inputField.error .fieldName{
    color:#EF5350;
  }