.chartContainer {
    overflow: auto;
    z-index: 0;
}

.donutChart{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding:16px;
}

.pie {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    box-shadow: 0 0 15px #ccc;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}
.donutChart.inactive .pie{
    background-color: transparent;
    box-shadow: none;
}
.donutChart.inactive .pieTotalCount{
    background-color: #E2E2E2;
    border:solid 2px gray;
    box-shadow: 0 0 15px #ccc;
}
.donutChart.inactive .pieTotalCount *{
    color:gray !important;
}
.pieSlice{
    border-radius: 50%;
    clip: rect(0px, 200px, 200px, 100px);
    height: 100%;
    position: absolute;
    width: 100%;
    pointer-events:none;  
}
.sliceProgress{
    border-radius: 50%;
    clip: rect(0px, 100px, 200px, 0px);
    height: 100%;
    position: absolute;
    width: 100%;
    font-family: monospace;
    font-size: 1.5rem;
    pointer-events: auto;
}

.small .pieSlice{
    clip:rect(0px, 150px, 150px, 75px);
}
.small .sliceProgress{
    clip:rect(0px, 75px, 200px, 0px);
}

.centerPrompt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: #F3F4F6;
    border-radius: 50%;
    pointer-events:none;
}

.donutChart.inactive .centerPrompt{
    background-color: transparent;
}

.donutChart.inactive .sliceProgress{
    background-color: transparent !important;
}

.pieTotalCountWrap{
    position:relative;
    width:100%;
    height:100%;
}

.pieTotalCount{
    position: absolute;
    margin: auto;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#F3F4F6;
    font-size:24px;
    text-align: center;
    z-index: 2;
    background-color: white;
    width:80%;
    height:80%;
    border-radius: 50%;
    box-shadow: 0 0 5px #ccc;
}

.small * .pieTotalCount{
    width:90%;
    height:90%;
}

.pieTotalCount div{
    position: relative;
    width:100%;
    height:100%;
}
.pieTotalCount div .pieCountNumber{
    position: absolute;
    top:calc(50% - 6px);
    left:50%;
    transform: translate(-50%, calc(-50% - 6px));
    width:100%;
    height:36px;
    line-height: 36px;
    text-align: center;
    color:dimgray;
    font-size:36px;
    font-weight: 700;
}
.pieTotalCount div .pieCountMessage{
    position: absolute;
    top: calc(50% + 10px);
    left: 50%;
    transform: translate(-50%, calc(-50% + 18px));
    width: 80%;
    height: auto;
    text-align: center;
    color: gray;
    font-size: 14px;
    font-weight: 100;
}

.pieChartSummary{
    background-color: #F3F4F6;
    border: solid 1px gainsboro;
    grid-template-rows: repeat(3, 1fr);
}

.pieChartSummary .block{
    border:solid 2px #E2E2E2;
}

.pieChartSummary .block.inactive{
    background-color: #E2E2E2;
    color:gray;
    border-color: gray;
}

.pieChartSummary .block.inactive .divider{
    background-color: gray;
}

.pieChartSummary > :nth-child(1){
    /* background-color: #fff3e0;
    border-color: #f57c00;
    color:#f57c00; */
    /* background-color: #7ca295;
    border-color: #104735;
    color:#104735;  */

    /* background-color: #7ca295;
    border-color: #104735;
    color:#104735;  */
}
.pieChartSummary > :nth-child(1) .divider{
    /* background-color: #f57c00; */
    /* background-color: #4c8273; */

    /* background-color: #104735; */
}

.pieChartSummary > :nth-child(2){
    /* background-color: #e3f2fd;
    border-color: #64b5f6;
    color:#64b5f6; */
    /* background-color: #c3d1cc;
    border-color: #719085;
    color:#719085; */

    /* background-color: #51b795;
    border-color: #1a7456;
    color:#1a7456; */
}
.pieChartSummary > :nth-child(2) .divider{
    /* background-color: #64b5f6; */

    /* background-color: #1a7456; */
}

.pieChartSummary > :nth-child(3){
    /* background-color: #f3e5f5;
    border-color: #9c27b0;
    color:#9c27b0; */
    /* background-color: #f3e3c3;
    border-color: #d2ab66;
    color:#d2ab66; */

    /* background-color: #acecd7;
    border-color: #26a67b;
    color:#26a67b; */
}
.pieChartSummary > :nth-child(3) .divider{
    /* background-color: #9c27b0; */
    /* background-color: #f3e3c3; */

    /* background-color: #26a67b; */
}

.pieChartSummary.small{
    max-height: 160px;
    height: auto;
    display: inline-block;
    width: calc(100% - 30px);
    position: absolute;
    right: 15px;
    left: auto;
    top: 42px;
    vertical-align: middle;
    transform: none;
    z-index: 3;
    margin-top: 3px;
    background-color: transparent;
    box-shadow: none;
}

.pieChartSummary.large{
    height:160px;
}

.summaryInfoBlock{
    grid-template-columns: 32px 1fr;
}

.summaryInfoBlock .point{
    grid-template-rows: 1fr 2px 1fr;
}

.summaryInfoBlockInner{
    position: relative;
    width:100%;
    height:100%;
}

.summaryInfoTop{
    grid-template-columns: 1fr;
}

.summaryInfoBlock  .icon svg{
    width:32px;
    height:32px;
}

.summaryInfoBlockIdentifier{
    width:16px;
    height:16px;
    border-radius: 50%;
}
.summaryInfoBlockBubble{
    border-radius: 50%;
    height:32px;
    width:32px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.summaryInfoBlockBubbleInner{
    position: relative;
    width:100%;
    height:100%;
}
.summaryInfoBlockBubbleInner svg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
/* .summaryInfoBlockName{
    font-size: 14px;
    grid-template-rows: 1fr;
} */
.summaryInfoBlockPercentage{
    font-size: 20px;
}

.chartData{
    width:100%;
    height: auto;
    min-height:100%;
    grid-template-columns: repeat(2, 1fr);
}

.pieSummarySection{
    overflow:hidden;
}
.pieSummarySection.small{
    width: 50%;
    vertical-align: middle;
    height: 100%;
    display: inline-block;
    position:relative;
    top:0;
}

.summarySectionTitle{
    position:absolute;
    top:32px;
    left:50%;
    transform: translate(-50%, 0);
    color:gray;
}
.centerPrompt * ::first-letter{
    text-transform:capitalize;
}


.chartContainer .placeholder{
    background-color: #e0f2f1;
    border: 2px dashed #26a69a;
}
.chartContainer .placeholder .block{
    grid-template-columns: 132px 1fr;
    color:#26a69a;
    font-size:22px;
}
.chartContainer .placeholder .block svg{
    width: 132px;
    height: 132px;
}