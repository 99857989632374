.appHeader{
    background-color: white;
    grid-template-columns: 64px 1fr 50vw;
    position:fixed;
    top:0;
    z-index: 3;
    height:64px;
}

.appHeader .logo img{
    width: 100%;
    height:auto;
}

.appHeader .tabs5 > div{
    grid-template-columns: repeat(5, 1fr) 64px 64px;
}
.appHeader .tabs4 > div{
    grid-template-columns: repeat(4, 1fr) 64px 64px;
}
.appHeader .tabs3 > div{
    grid-template-columns: repeat(3, 1fr) 64px 64px;
}
.appHeader .tabs2 > div{
    grid-template-columns: repeat(2, 1fr) 64px 64px;
}

.appHeader .tabs4 .icon svg{
    width:32px;
    height:32px;
}
.appHeader .tabs3 .icon svg{
    width:32px;
    height:32px;
}
.appHeader .tabs2 .icon svg{
    width:32px;
    height:32px;
}

.appHeader .tab{
    grid-template-rows: 4px 1fr 4px;
    position: relative;
    font-size:18px;
}


.appHeader .tab.alt{
    grid-template-rows: 1fr;
    color:dimgray;
}

.appHeader .tab .prompt{
    /* grid-template-columns: 1fr minmax(auto, 48px); */
    grid-template-columns: 1fr;
    color:dimgray;
}

.appHeader .tab.offline .prompt{
    color:gainsboro;
}

.appHeader .tabs.alt .tab .prompt{
    grid-template-columns: 1fr;
}

.appHeader .tabs.alt .tab .alert{
    right:8px;
}

/* .tabs.header .tab.active * :not(.alert), .appHeader .tab.active * :not(.alert){
    color:inherit;
} */
/* .tabs.header .tab.active *, .appHeader .tab.active *{ */
    /* font-weight: 500; */
    /* color:#42a5f5; */
    /* color:#4c8273; */
    /* color:#4c8273; */
    /* color: #26a69a; */
/* } */

/* .appHeader .tab.active .status{ */
    /* background-color: #42a5f5; */
    /* background-color: #4c8273; */
    /* background-color: #4c8273; */
    /* background-color: #26a69a; */
/* } */

/* .tabs.header .tab:not(.alt):hover, .appHeader .tab:not(.alt):hover{ */
    /* background-color: #e3f2fd; */
    /* background-color: #e0f2f1; */
/* } */

.appHeader .tab.offline:hover{
    background-color: #EFEFEF;
}

.appHeader .tab.offline.active .status{
    background-color: #E2E2E2 !important;
}

.appHeader .tab.offline:hover .status{
    background-color: dimgray !important;
}

.appHeader .tab.alt:hover{
    background-color: #e0f2f1;
    color:#26a69a;
}

/* .appHeader .tab:not(.active):hover .status{ */
    /* background-color: #1e88e5; */
    /* background-color: #00897b;
} */

.tab .alert{
    color:#f44336 !important;
    position:absolute;
    top:4px;
    right:0px;
}

.appHeader .policyNav{
    width:auto;
    min-width:calc(50vw - 64px);
    grid-template-columns: 64px 1fr 64px;
    color:#0d47a1;
    font-size: 18px;
}

.policyNav .scroll{
    border-radius: 50%;
}
.policyNav .scroll svg{
    width:36px;
    height:36px;
}

.policyNav .scroll:hover{
    background-color:#e3f2fd;
}

.notificationsBtn svg{
    color:#1e88e5; 
}

.notificationsBtn .count{
    position:absolute;
    bottom:4px;
    right:4px;
    background-color: #f44336;
    color: white;
    font-weight: 500;
    border-radius: 16px;
    min-width: 24px;
    padding:2px;
    font-size:14px;
}

.appHeader .accountDropdown{
    width: max-content;
    height: 40px;
    font-weight: 500;
    border: none !important;
}

.appHeader .accountDropdown > div {
    width: max-content;
}