.dashboard .right.panel{
    grid-template-rows: repeat(3, 48px) 1fr;
    background-color: white;
    max-height: calc(100vh - 80px);
}

.dashboard .left.panel{
    grid-template-rows: auto 1fr;
    gap: 16px;
}

.dashboard .left .top.section{
    grid-template-rows: 48px 1fr;
    background-color: white;
}

.dashboard .left .top.section .header{
    grid-template-columns: minmax(max-content, 180px) 1fr minmax(max-content, 180px);
}
.dashboard .left .top.section .details{
    /* grid-template-rows: .5fr 1fr; */
    grid-template-rows: 125px min-content;
}

.dashboard .left .top.section .details .aggregateData{
    grid-template-columns: repeat(3, 1fr);
}

.dashboard .left .top.section .details .aggregateData .data{
    grid-template-rows: 28px 8px 1fr;
    gap:8px;
}

.dashboard .left .top.section .details .aggregateData .data .header{
    /* grid-template-columns: 1fr minmax(100px, auto); */
    grid-template-columns: 1fr auto;
}

.dashboard .left .top.section .details .aggregateData .data .viewMore{
    display: none;
}

.dashboard .left .top.section .details .aggregateData .data.active .viewMore:hover{
    color:#00897b;
    outline:solid 2px #00897b;
    background-color: white;
}

.dashboard .left .top.section .details .aggregateData .data:not(.active) .viewMore:hover{
    color:#00897b;
    outline:solid 2px #00897b;
    background-color: #e0f2f1;
}

.dashboard .left .top.section .details .aggregateData .data:hover .viewMore{
    grid-template-columns: auto auto;
    display: inline-grid;
    gap:8px;
}

.dashboard .left .top.section .details .aggregateData .data:not(.active):hover{
    color:#00897b;
    border-color:#00897b;
}

.dashboard .left .top.section .details .aggregateData .data:hover .divider{
    background-color: #00897b;
}

.dashboard .left .top.section .details .aggregateData .data .count{
    font-size: 24px;
}

.dashboard .left .top.section .details .aggregateData .data{
    border: 2px solid dimgray;
    color: dimgray;
}

.dashboard .left .top.section .details .aggregateData .data.active:not(.idle){
    /* background-color: #e0f2f1;
    border: 2px solid #26a69a;
    color: #26a69a; */
    background-color: #c3d1cc;
    border: 2px solid #4c8273;
    color: #4c8273;
}

.dashboard .left .top.section .details .aggregateData .data .divider{
    background-color: gray;
}
.dashboard .left .top.section .details .aggregateData .data.active .divider{
    /* background-color: #26a69a; */
    background-color: #4c8273;
}

.dashboard .left .top.section .details .aggregateData .data.active.idle{
    background-color: #E2E2E2;
    border: 2px solid dimgray;
    color: dimgray;
    cursor:default !important;
}

.dashboard .left .top.section .details .aggregateData .data.idle:not(.active){
    border: 2px solid dimgray;
    color: dimgray;
    cursor:default !important;
}

.dashboard .left .top.section .details .aggregateData .data.idle .divider{
    background-color: dimgray;
}

.dashboard .left .top.section .details .breakdown{
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    color: dimgray;
}

.dashboard .left .top.section .details .breakdown .block{
    /* grid-auto-rows: min-content; */
}

.dashboard .left .top.section .details .breakdown .row:not(.btn) .prompt{
    color: black;
}

.dashboard .left .top.section .details .breakdown .data .row{
    background-color: #F3F4F6;
    border: 1px solid gainsboro;
    grid-template-columns: 24px 1fr auto;
    height: 48px;
}

.dashboard .left .top.section .details .breakdown .data .row.btn{
    background-color: #e0f2f1;
    border: 1px solid #009688;
    color:#009688;
    border-width: 2px;
}
.dashboard .left .top.section .details .breakdown .data .row.btn:hover{
    background-color: #b2dfdb;
    border-color: #004d40;
    color:#004d40;
}

.dashboard .left .top.section .details .breakdown .visuals .row{
    grid-template-rows: repeat(2, 1fr);
    height: 48px;
    line-height: 1;
}

.dashboard .left .bottom.section{
    grid-template-rows: 48px 1fr;
    background-color: white;
}

.dashboard .left .bottom.section .header{
    grid-template-columns: 1fr 108px 1fr;
}

.dashboard .left .bottom.section .header .filters{
    color:dimgray;
    font-weight: 100;
    grid-template-columns: auto 4px auto;
    height: 36px;
}

.dashboard .left .bottom.section .header .filters .gCW{
    width: inherit;
}


.dashboard .left .bottom.section.loading{
    grid-template-rows: 1fr;
}

.frequencyReportList .content .list{
    grid-auto-rows: 56px;
    overflow-x:hidden;
}

.frequencyReportList .content .list.alt{
    grid-auto-rows: unset;
    overflow-x:hidden;
}


.frequencyReportList .content .list .row{
    border:solid 2px gainsboro;
    grid-template-columns: minmax(auto,160px) 1fr minmax(auto,160px);
    height: 56px;
    background-color: white;
    color: dimgray;
}

.frequencyReportList .content .list .row:hover{
    color: #26a69a;
    border-width: 2px;
    border-color: #26a69a;
    background-color: #e0f2f1;
}

.frequencyReportList .content .list .row.idle:hover{
    background-color: #E2E2E2;
    border: 2px solid dimgray;
    color: dimgray;
    cursor:default !important;
}

.frequencyReportList .content .list .row.idle:hover svg,
.frequencyReportList .content .list .row.idle:hover .viewMore,
.dashboard .left .top.section .details .aggregateData .data.active.idle .viewMore:hover{
    color: dimgray;
    outline-color:dimgray;
}

.dashboard .left .top.section .details .aggregateData .data.idle:not(.active) .viewMore:hover{
    background-color:#E2E2E2;
    outline-color:dimgray;
    color:dimgray;
}

.frequencyReportList .content .list .row.active{
    background-color: #e0f2f1;
    border: 2px solid #00897b;
    color: #009688;
}
.frequencyReportList .content .list .row.active svg{
    color: #009688;
}

.frequencyReportList .content .list .row > .g{
    grid-template-columns: 1fr 1fr;
}

.frequencyReportList .content .list .row:not(.hovering) .viewMore:not(.active){
    display: none;
}

.frequencyReportList .content .list .row:not(.hovering) .viewMore.failed{
    color:#f44336;
    outline-color: #f44336;
    grid-template-columns: auto auto;
    display: inline-grid;
}

.frequencyReportList .content .list .row.hovering .viewMore{
    grid-template-columns: auto auto;
    display: inline-grid;
    gap:8px;
    width: max-content;
    color:gray;
}
.frequencyReportList .content .list .row.hovering:hover .viewMore{
    color: #00897b;
}

.frequencyReportList .content .list .row .viewMore:hover{
    color: #00897b;
    background-color: white;
    outline: 2px #00897b solid;
}

.frequencyReportList .content .list .row:not(:hover) .icon.increase{
    color:#66bb6a;
}

.frequencyReportList .content .list .row:not(:hover) .icon.decrease{
    color:#ef5350;
}

.dashboard .left .top.section .details .placeholder{
    background-color: #e0f2f1;
    border: 2px dashed #26a69a;
}
.dashboard .left .top.section .details .placeholder .prompt{
    grid-template-rows: 1fr auto;
}
.dashboard .left .top.section .details .placeholder .prompt .subPrompt{
    font-size:16px;
    font-weight: 100;
}
.dashboard .left .top.section .details .placeholder .block{
    grid-template-columns: 48px 1fr;
    color:#26a69a;
    font-size:20px;
}

.dashboard .left .top.section .details .placeholder .block svg{
    width: 48px;
    height: 48px;
}

.dashboard .left .top.section .newAccountBlock .placeholder{
    background-color: #e0f2f1;
    border: 2px dashed #26a69a;
}

.dashboard .left .top.section .newAccountBlock .placeholder .prompt{
    height: inherit;
}
.dashboard .left .top.section .newAccountBlock .placeholder .prompt .subPrompt{
    font-size:16px;
    font-weight: 100;
}
.dashboard .left .top.section .newAccountBlock .placeholder .block{
    grid-template-rows: 1fr 132px 1fr;
    color:#26a69a;
    font-size:22px;
    height: inherit;
}

.dashboard .left .top.section .newAccountBlock .placeholder .action{
    grid-template-columns: 132px 1fr 132px;
}

.dashboard .left .top.section .newAccountBlock .placeholder .block .icon svg{
    width: 132px;
    height: 132px;
}

.dashboard .left .top.section .newAccountBlock .placeholder .btn{
    width: max-content;
}

.dashboard .right .quickReports{
    grid-template-columns: repeat(2, 1fr);
}

.dashboard .right .quickReports .option{
    grid-template-columns: 36px 1fr;
    border: solid 2px gainsboro;
    color: dimgray;
}
.dashboard .right .quickReports .option:hover{
    background-color: #e0f2f1;
    border-color: #26a69a;
    color: #26a69a;
}
.dashboard .right .quickReports .option .prompt{
    grid-template-columns: 1fr min-content;
}
