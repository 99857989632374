.profile-card-container{
   height: 600px;
   overflow: hidden;
   overflow-y: auto;
}

.profile-card-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-color: transparent;
}

.profile-card {
    grid-template-rows: repeat(auto, auto);
    border: 1px solid black;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 800px;
    height: auto;
    background-color: rgb(245, 245, 245, .8);
}

.participantsLabel{
    height: 30px;
    width: fit-content;
}

.expandBeneficiary{
    cursor: pointer;
    height: 30px;
    width: fit-content;
}

.dividerHeight{
    margin-top: 10px;
}
  
.profile-detail {
    width: 130px;
}

.nameHeader{
    width:fit-content;
}

.cardInternalRow{
    grid-template-columns: repeat(2, auto) 1fr;
    height: 30px;
}
.cardNameRow{
    margin-left: 20px;
    grid-template-columns: repeat(7, auto) 1fr;
    height: 30px;
}
.cardLocationRow{
    margin-left: 20px;
    grid-template-columns: repeat(6, auto) 1fr;
    height: 30px;
}

.cardBeneficiaryRow{
    margin-left: 20px;
    grid-template-columns: repeat(4, auto) 1fr;
    height: 30px;
}

.bLocationRow{
    margin-left: 20px;
    grid-template-columns: repeat(6, auto) 1fr;
    height: 30px;
}

.redStyle{
    background-color: #ffcdd2;
    color: #b71c1c;
    font-weight: 500;
    border: 2px solid #b71c1c;
    cursor: pointer;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

.greenStyle  {
    background-color: #C8E6C9;
    color: #2E7D32;
    font-weight: 500;
    border: 2px solid #2E7D32;
    cursor: pointer;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;   
}

.greenStyleHover:hover {
background-color: #c1e4c2;
}

.redStyleHover:hover{
    background-color: #fcb2b9;
    color: #b71c1c;
    font-weight: 500;
    border: 2px solid #b71c1c;
    cursor: pointer;
    padding: 1px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

.uploadInfo{
    
    grid-template-rows: repeat(auto, auto);
    border: 1px solid black;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 920px;
    height: auto;
    background-color: rgb(245, 245, 245, .8);
    z-index: -1;
}

.infoErrors{
    grid-template-columns: 44% auto 10px auto 1fr;
    grid-template-rows: repeat(auto,auto);

}

