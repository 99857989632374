.accountCenterContainer{
    background-color: lightgray;
    grid-template-rows: 48px 1fr;
    border: 2px solid black;
}

.accountCenterTabs{
    grid-template-columns: 300px 300px 1fr;
    border: 2px solid black;
}

.accountCenterContent{
    grid-template-columns: 1fr 2fr;
    border: 2px solid black;
}

.accountCenterContent .accountList{
    grid-template-rows: 64px 1fr;
    border: 2px solid black;
    min-width: 200px;
}

.accountCenterContent .accountList .accountListRows{
    /* grid-template-rows: auto; */
    border: 2px solid black;
}

.accountCenterContent .accountList .accountListRow{
    height: 100px;
}

.accountCenterContent .accountDetails{
    grid-template-rows: 64px 1fr 1fr;
    border: 2px solid black;
}

.accountCenterContent .accountDetails .userListRows{
    /* grid-template-rows: auto; */
    border: 2px solid black;
    overflow: auto;
    max-height: 100%;
}

.accountCenterContent .accountDetails .userListRows .userListRow{
    height: 64px;
}

.accountCenterContent .accountDetails .permissionsContent{
    grid-template-rows: 64px 1fr 1fr;
    border: 2px solid black;
}

.accountCenterContent .accountDetails .permissionsContent .permissionBtns{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border: 2px solid black;
}
