.loginBoard{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height:100%;
    width:100%;
    background-image: url("../Images/2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.loginBlock{
    width:450px;
    height:auto;
    background-color: white;
    /* grid-template-rows: 96px auto 8px 1fr; */
    grid-template-rows: 210px auto 8px 1fr;
    gap:8px;
    max-height: calc(100vh - 16px);
}

.loginBlock .messageGrid{
    grid-template-columns: 1fr 1fr 1fr;
}

.loginBlock .message{
    color:dimgray;
    font-weight: 100;
    font-size: 18px;
}

.loginBlock .wrap{
    overflow:auto;
}

.loginBlock .form{
    grid-template-rows: 1fr repeat(2, auto);
    font-size: 24px;
    gap:16px;
    height: auto;
}
.loginBlock.twoFactor .form{
    grid-template-rows: 48px auto auto 48px auto;
    font-size: 24px;
    gap:16px;
}

.loginBlock.reset .form, .loginBlock.twoFactor .form{
    grid-template-rows: 48px auto auto auto auto;
    font-size: 24px;
    gap:16px;
}

.loginBlock .form .twoFactorSetup{
    grid-template-rows: repeat(auto-fill, 32px);
    gap: 0px;
}

.loginBlock .form .fields{
    grid-template-rows: repeat(auto-fill, 64px);
    gap: 8px;
}
.loginBlock .form .fields .field{
    grid-template-columns: 64px 1fr 64px;
}
.loginBlock .form .fields .field :first-child svg{
    width:36px;
    height:36px;
    color: #719085;
}

.loginBlock .form .fields .textField{
    grid-template-columns: 64px 1fr 100px;
    padding-bottom: 30px;
}
.loginBlock .form .fields .textField .svgIcon svg{
    width:36px;
    height:36px;
    color: #719085;
}

.loginBlock.login .form .options{
    grid-template-rows: repeat(2, 1fr);
    gap:8px;
}

.passwordReset{
    color:gray;
    font-size: 18px;
}
.passwordReset:hover{
    background-color: #EFEFEF;
    color:dimgray;
    outline:solid 2px gray;
}

.loginBoard input{
    border-radius: 5px;
    color:dimgray;
}
.loginBoard .inputField input:focus{
    outline:solid 2px gray;
    border-color: transparent;
    background-color: white;
}

.goBack{
    grid-template-columns: 48px auto;
    color:gray;
    gap:8px;
    border-bottom:solid 2px transparent;
}
.goBack:hover{
    color:dimgray;
    background-color: whitesmoke;
    border-color: #104735;
}

.loginBlock .field .active svg{
    color:#104735 !important;
}

.loginBlock .textField .active svg{
    color:#104735 !important;
}

.loginBlock .btnWIcon{
    border-color:#104735;
    color:#104735;
    grid-template-columns: 24px 1fr 24px;
}

.loginBlock .active.btnWIcon{
    background-color:#104735;
    color:white;
}

.loginBlock .active.btnWIcon:hover{
    background-color: white;
    color:#104735;
}

.loginBlock .header{
    /* grid-template-columns: 96px 1fr; */
    grid-template-columns: 320px;
    font-size: 24px;
    color:#104735;
}

.lockIcon{
    height:48px;
    width:48px;
    border-radius: 50%;
    outline:solid 2px #104735;
}

.lockIcon svg{
    color:#104735;
    width:36px;
    height:auto;
}

.loginBlock .prompt{
    color:dimgray;
    font-weight: 100;
    height: fit-content;
}

.loginBlock .prompt.alt{
    grid-template-rows: 36px repeat(6, 24px);
}

.loginBtn{
    height:48px;
}

.loginBlock.create .form{
    grid-template-rows: 48px auto auto 48px auto;
    font-size: 24px;
    gap:16px;
}

.errorStatus{
    background-color: #ffebee;
    color:#e53935;
    font-weight: 100;
    border-radius: 5px;
}

.successStatus{
    background-color: #ffebee;
    color:#a7faae;
    font-weight: 100;
    border-radius: 5px;
}

.loginBlock .optionsRow{
    grid-template-columns: 4fr 1fr 1fr;
}

.loginBlock .twoFactorOptions{
    font-size: 16px;
    width:160px;
    padding: 1px;
}

.loginBlock .optionsStyle{
    border: none;
    border-bottom: 2px solid;
    border-color: #104735;
    border-width: 2px;
}

.loginBlock .twoFactorOptions:hover{ 
    cursor: pointer;
    border: none;
}

.loginBlock .optionsMenu{
    font-size: 14px;
    position: absolute;
    top: 100%;
    z-index: 1;
}

.loginBlock .optionsMenu a{
    background-color: white;
    border: solid; 
    display: block;
    cursor: pointer;
}

.loginBlock .optionsMenu a:hover{
    background-color: lightgrey;
    cursor: pointer;
}


.loginBlock .checkedSelection{
    grid-template-columns: 150px 150px 100px;
}

.qrBox{
    /* position: fixed; */
    /* right: 0; */
    /* top: 50%; */
    /* transform: translateY(-250%) translateX(-45%); */
    /* border: 1px solid #ccc; */
    background-color: white;
    padding: 0px;
    width:'100%';
    /* z-index: 10; */
}

.loginBlock .twoFactorChoices{
    grid-template-columns: 1fr 1fr;
    gap:16px;
    font-size: 22px;
}

.loginBlock .twoFactorChoice{
    border:solid;
    border-color: lightgray;
    padding-top: 40px;
    padding-bottom: 40px;
    cursor: pointer;
    border-radius: 5px;
    color: gray;
}

.loginBlock .twoFactorChoice:hover{
    background-color: #e0f2f1;
    border-color: #009688;
    color: #004d40;
}

.loginBlock .sendCodeBox{
    grid-template-columns: 1.5fr 1fr;
    /* border:solid;
    border-color: lightgrey; */
    height: 60px;
}
.loginBlock .sendCode{
    border: solid;
    border-radius: 5px;
    border-color: #009688;
    padding: 5px;
    font-size: 12px;
    width: 120px;
    border-width: 2px;
}
.loginBlock .sendCodeInactive{
    border: solid;
    border-radius: 5px;
    border-color: lightgray;
    color: lightgray;
    padding: 5px;
    font-size: 12px;
    width: 120px;
    border-width: 2px;
}
.loginBlock .sentCode{
    border: solid;
    border-color: lightgrey;
    padding: 5px;
    font-size: 12px;
    width: 120px;
    border-width: 2px;
}
.loginBlock .resendCode{   
    padding: 5px;
    font-size: 12px;
    width: 120px;
    border: solid 2px lightgray;
    border-radius: 5px;
    border-width: 2px;
}

.loginBlock .sendCode:hover{
    /* border-color: lightgrey; */
    background-color: #e0f2f1;
    cursor: pointer;
}

.loginBlock .twoFactorSendCode{
    border: solid;
    border-radius: 5px;
    border-color: #009688;
    padding: 5px;
    font-size: 12px;
    height: 32px;
    width: 90px;
    border-width: 2px;
}
.loginBlock .twoFactorSendCode:hover{
    /* border-color: lightgrey; */
    background-color: #e0f2f1;
    cursor: pointer;
}

.loginBlock .twoFactorSentCode{
    border: solid;
    border-color: lightgrey;
    padding: 5px;
    font-size: 12px;
    height: 32px;
    width: 90px;
    border-width: 2px;
}
.loginBlock .twoFactorResendCode{   
    padding: 5px;
    font-size: 12px;
    height: 32px;
    width: 90px;
    border: solid 2px lightgray;
    border-radius: 5px;
    border-width: 2px;
}
.loginBlock .twoFactorSendCodeBox{
    border:solid;
    border-width: 2px;
    width: 100%;
    border-color: #009688; 
    color:#009688; 
    background-color: #e0f2f1;
    height: 34px;
    margin-top: 16px;
}

.loginBlock .qrInfo{
    font-size: 16px;
    background-color: #86b1a2;
    border: solid;
    border-color: #86b1a2;
    border-width: 1px;
}

.loginBlock .qrInfo:hover{
    cursor: pointer;
    background-color:#a0d1c0;
}

.loginBlock .qrInfoBox{
    font-size: 12px;
    padding:10px;
    background-color: rgba(0,0,0,0.05);
    border: solid;
    border-color: lightgrey;
    border-width: 1px;
}

.loginBlock .microsoftLogo:hover{
    cursor: pointer;
}

.loginBlock .googleLogo:hover{
    cursor: pointer;
}

.loginBlock .tosContainer{
    box-sizing: border-box; 
    border-radius: 5px;
    border: 2px solid grey;
    height: 300px;
    font-size: 14px;
    padding: 16px;
    padding-bottom: 20px;
    padding-top: 10px;
}

.loginBlock .tosCheckBoxRow{
    grid-template-columns: 1fr 90% 1fr;
    font-size: 14px;
}

.loginBlock .tosContents{
    gap:8px;
}

.loginBlock .tosHeader{
    font-size:16px;
}

.loginBlock .blueLink{
    color: #0000EE;
    text-decoration: underline;
}

.createPasswordForm{
    grid-template-rows: auto auto auto auto;
    gap:8px;
}

.createPasswordTitle{
    font-size: 20px;
}

.spanAllRows{
    grid-row: 1 / -1;
}