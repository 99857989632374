@media (min-width:768px) and (max-width:1024px) {
    body{
        overflow-y: auto;
        overflow-x: hidden;
        min-width: unset;
        height: auto;
    }
    
    #root{
        grid-auto-rows: auto;
        height: auto;
        min-width: unset;
    }

    .App{
        grid-auto-rows: 64px auto;
        overflow-y: auto;
        height: auto;
        gap:8px;
    }

    .overlay{
        position:fixed;
    }
    
    .appHeader .tabs.alt > div{
        grid-template-columns: repeat(3, 1fr) 64px 64px;
    }
    
    .appHeader .policyNav{
        min-width: unset;
        width: 100%;
    }

    .lifeTracPortal{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: auto;
        border-radius: 0;
        width:100%;
        padding: 0 8px 8px 8px;
    }

    .activity.dashboard .dashboardHub, .dashboard .left.panel{
        gap: 8px;
    }

    .activity.details{
        grid-template-rows: 1fr;
    }

    .activity.messages{
        grid-template-rows: 48px auto 48px;
    }

    .activity.timeline{
        height: auto;
        overflow: auto;
        grid-template-rows:84px 1fr;
    }

    .policyScroller, .notifications{
        width:80%;
        height:80%;
    }

    .overlay .body{
        overflow-y: auto;
    }

    .messageBoardBottomBar{
        position: fixed;
    }

    .chartData{
        width:100%;
        height: auto;
        min-height:100%;
        grid-template-rows: 1fr 96px;
        grid-template-columns: unset;
    }

    .pieChartSummary {
        background-color: #F3F4F6;
        border: solid 1px gainsboro;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: unset;
    }

    .summaryBar{
        grid-template-columns: minmax(auto, 32px) 1fr  minmax(auto, 32px);
    }

    .individualProfile .selectedProfile .rowData{
        grid-template-columns: repeat(3, auto);
    }
    .individualProfile .selectedProfile .row .inputField{
        width: auto;
    }
    .individualProfile .selectedProfile .row .inputField input{
        width: 100%;
    }
    .individualProfile .selectedProfile .rowData.important{
        grid-template-columns: repeat(2, 1fr);
    }
    .individualProfile .selectedProfile .rowData.location{
        grid-template-columns: repeat(3, 1fr);
    }
    .individualProfile .selectedProfile .rowData.death{
        grid-template-columns: repeat(2, 1fr);
    }

    .weeklyReportList .content .list .row.hovering{
        grid-template-columns: repeat(2, .5fr) 1fr 1fr;
    }

    .analyticsHub .reportBuilder{
        max-height: calc(100vh - 128px);
    }

    .dashboard .left .top.section .newAccountBlock .placeholder .block{
        /* grid-template-columns: 72px 1fr; */
    }
    .dashboard .left .top.section .newAccountBlock .placeholder .block .icon svg{
        width: 72px;
        height: 72px;
    }
    .dashboard .left .top.section .newAccountBlock .placeholder .action{
        grid-template-columns: auto 1fr;
    }
    .fileShareBox .filePreviewContainer{
        max-height: calc(100vh - 228px);
    }
    .columnsContent .fileDetailsBox{
        max-height: calc(100vh - 232px);
    }
    .adminBoard .settingsContainer .manageAccountsContent .rightPanel .manageHeaderRow{
        grid-template-columns: 0px 1fr 60px 60px;
    }
    .adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .trackBar{
        grid-template-columns: 64px repeat(3, 1fr);
    }
    .reportingHub .reportBuilder{
        /* grid-template-columns: 300px 1fr 0px; */
        max-height: calc(100vh - 128px);
    }
    .adminBoard .settingsContainer .manageAccountsContent .rightPanel .userPermissions{
        grid-template-columns: auto 1fr;
        gap: 24px;
        overflow: auto;
        max-height: 320px;
    }
    .adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .userPermissions{
        grid-template-columns: auto 1fr;
        gap: 24px;
        overflow: auto;
        max-height: 270px;
    }
}

@media (min-width:768px) and (max-height:786px) {
    .lifeTracPortal{
        padding:0;
    }
    .activity.dashboard .dashboardHub, .dashboardHub .left.panel{
        gap:8px;
    }
    .analyticsHub .reportBuilder {
        grid-template-columns: 300px 1fr;
        max-height: calc(100vh - 128px);
    }
    .dashboard .left .top.section .details{
        height: auto;
    }
    .fileShareBox .filePreviewContainer{
        max-height: calc(100vh - 228px);
    }
    .columnsContent .fileDetailsBox{
        max-height: calc(100vh - 232px);
    }
    .adminBoard .settingsContainer .manageAccountsContent .rightPanel .manageHeaderRow{
        grid-template-columns: 0px 1fr 60px 60px;
    }
    
}