.policyDetailsMessageBoard{
    height: auto;
    width: 100%;
    background-color: inherit;
    overflow: auto;
    overflow-x:hidden;
}
.policyDetailsMessageBoard.loading{
    height: 100%;
}
.policyDetailsMessageBoard.loading > div{
    gap:8px;
}
.policyDetailsMessageBoard.loading svg{
    color:#1e88e5 !important;
}
.policyDetailsMessageBoard.loading .prompt, .preTranscriptContainer .prompt{
    color:dimgray;
}
.preTranscriptContainer svg{
    width:48px;
    height: 48px;
}
.preTranscriptContainer{
    grid-template-rows: repeat(3, auto);
}

.messageInfoHeader{
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: inherit;
    grid-template-columns: 16px calc(100% - 96px) 16px;
    font-weight: 500;
}
.policyDetailsMessageBoard::-webkit-scrollbar-thumb, .messageBox::-webkit-scrollbar-thumb{
    border-color: white;
}
.policyDetailsMessageBoard::-webkit-scrollbar-track, .messageBox::-webkit-scrollbar-track{
    background-color:white;
}
.messageBoardBottomBar{
    min-height: 36px;
    text-align: center;
    position: sticky;
    bottom: 8px;
    left: 8px;
    width: calc(100% - 16px);
    grid-template-columns: 1fr 36px;
    gap:8px;
}

.messageBoardBottomBar.alt{
    position:absolute;
}

.messageBoardBottomBarInnerWrap{
    position:relative;
    width:100%;
    height:100%;
    text-align: center;
}
.messageBoardMsgBox{
    display: grid;
    vertical-align: middle;
    width:100%;
    background-color:inherit;
    grid-template-columns: 1fr 48px;
    gap:8px;
}

.messageBoardBottomHandle{
    position: relative;
    width: 1px;
    max-width: 75%;
    height: 1px;
    background-color: transparent;
    float:right;
    clear:both;
}

.messageBubble{
    position: relative;
    width: auto;
    max-width: 75%;
    height: auto;
    padding: 12px;
    text-align: left;
    margin-bottom:24px;
    clear:both;
    white-space: pre-wrap;
    font-size:18px;
}

.messageBubble.sent{
    background-color: #104735;
    color: white;
    float:right;
}

.messageBubble.received{
    background-color: white;
    border:solid #EFEFEF 2px;
    color: black;
    float:left;
}

.messageBubble.loading{
    background-color: #ABDBCA;
    color: white;
    float:right;
}
.messageBubble.failed .messageNameOfSender{
    display:none;
}

.messageBubble.failed{
    background-color: #E2E2E2;
    color: gray;
    float:right;
}
.messageBubble.failed::after {
    content: 'Message failed';
    color:gray;
    position:absolute;
    bottom:calc(100% + 5px);
    right: 0;
    width:220px;
    text-align: right;
}

.messageBubble:hover{
    cursor:default;
}

.policyDetailsMessageBoard > div:last-of-type{
    margin-bottom:0;
}
.messageBox{
    letter-spacing: inherit;
    padding: 8px;
    border: solid 1px #EFEFEF;
    box-sizing: content-box;
    font-size: 16px;
    height: auto;
    -webkit-tap-highlight-color: transparent;
    display: block;
    width: 100%;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    resize: none;
    font-family: inherit;
    border-radius: 5px;
    max-height:90px;
    margin-left:1px;
    margin-bottom:1px;
    outline:solid #E2E2E2 2px;
}
.messageBox:active, .messageBox:focus{
    outline:solid 1px gray;
}

.messageBoxSendBtn{
    width: 40px;
    height: 40px;
    background-color: #E2E2E2;
    border-radius: 50%;
    color:gray;
}
.messageBoxSendBtn:hover{
    color:#E2E2E2;
    background-color:gray;
    cursor:pointer;
}
.messageBoxSendBtn.active{
    background-color:#104735;
    color:white;
}
.messageBoxSendBtn.active:hover{
    background-color:white;
    border:solid 2px #104735;
    color:#104735;
}
.messageBoxSendBtnInnerWrap{
    position:relative;
    height:100%;
    width:100%;
}
.messageBoxSendBtnInnerWrap svg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.messageBubble .messageTimeStamp{
    display: block;
    position:absolute;
    bottom:100%;
    color:gray;
    min-width:100px;
    height:24px;
    font-size:18px;
}

.messageBubble.sent .messageTimeStamp, .messageBubble.failed .messageTimeStamp{
    right:-10px;
}

.messageBubble.received .messageTimeStamp{
    left:-10px;
}

.messageBubble.sent .messageTimeStamp .messageTimeStampInnerWrap div, .messageBubble.failed .messageTimeStamp .messageTimeStampInnerWrap div{
    animation: timeStampAnimationHideSent ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    position:absolute;
    bottom:0;
}
.messageBubble.sent .messageTimeStamp.show .messageTimeStampInnerWrap div, .messageBubble.failed .messageTimeStamp.show .messageTimeStampInnerWrap div{
    animation: timeStampAnimationShowSent ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    bottom:0;
}


.messageBubble.received .messageTimeStamp .messageTimeStampInnerWrap div{
    animation: timeStampAnimationHideReceived ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    position:absolute;
    bottom:0;
}
.messageBubble.received .messageTimeStamp.show .messageTimeStampInnerWrap div{
    animation: timeStampAnimationShowReceived ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    bottom:0;
}

.messageTimeStamp.show{
    display: block;
    position:absolute;
    bottom:100%;
    color:gray;
    width:100%;
    font-size:16px;
    right:0;
}
.messageTimeStamp.full.show{
    bottom:calc(100% + 20px);
}

.messageTimeStamp.full{
    bottom:calc(100% + 20px);
}

.messageBubble.received .messageTimeStamp.show{
    right:auto;
    left:0;
}

.messageTimeStampInnerWrap{
    position:relative;
    width:100%;
    height:100%;
}

@keyframes timeStampAnimationShowSent{
    0% {
    right:-100%;
    }
    100% {
    right:5px;
    }
}

@keyframes timeStampAnimationHideSent{
    0% {
        right:5px;
    }
    100% {
        right:-100%;
    }
}

@keyframes timeStampAnimationShowReceived{
    0% {
    left:-100%;
    }
    100% {
    left:5px;
    }
}

@keyframes timeStampAnimationHideReceived{
    0% {
        left:5px;
    }
    100% {
        left:-100%;
    }
}

@keyframes hoveredBubble{
    0% {
        margin-top:20px;
    }
    100% {
        margin-top:0;
    }
}

.timeStampSeparator{
    clear: both;
    color: dimgray;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 100;
}

.messageNameOfSender{
    position: absolute;
    top: -25px;
    right: 8px;
    color: dimgray;
    min-width: 140px;
    text-align: right;
    font-weight: 500;
}

.messageBubble.received .messageNameOfSender{
    text-align: left;
    right:auto;
    left:8px;
}

.messageBubble.received.full:not(.show), .messageBubbleSent.full:not(.show), .messageBubbleLoading.full:not(.show){
    margin-top:20px;
    animation: hoveredBubble ease;
    animation-iteration-count: 1;
    animation-duration: .2s;
    animation-fill-mode: both;
    bottom:0;
}
.messageBubble.received.full.show, .messageBubbleSent.full.show, .messageBubbleLoading.full.show{
    margin-top:20px;
}

.communicationWrapper{
    grid-template-rows: 32px 1fr minmax(48px, auto);
}