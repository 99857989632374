.adminBoard .settingsContainer{
    grid-template-rows: 50px 1fr;
}

.adminBoard .settingsContainer .settingsTabs{
    grid-template-columns: 1fr 1fr .75fr ;
    border-bottom: 2px solid lightgray;
}

.adminBoard .settingsContainer .settingsTabs .settingTabSelected{
    font-weight: 500;
    color: #00897b;
    border-bottom: 2px solid #00897b;
    background-color: #e0f2f1;
}

.adminBoard .settingsContainer .settingsTabs .settingTabDisabled{
    color: gray;
    /* border-bottom: 2px solid lightgray; */
    cursor: pointer;
}

.adminBoard .settingsContainer .settingsTabs .settingTabDisabled:hover{
    color: #00897b;
    border-bottom: 2px solid #00897b;
    background-color: #e0f2f1;
}

.adminBoard .settingsContainer .manageAccountsContent{
    grid-template-columns: 300px 1fr;
}

.adminBoard .settingsContainer .manageAccountsContent{
    grid-template-columns: 300px 1fr;
}

.adminBoard .settingsContainer .manageAccountsContent .selectedAccount{
    color: #26a69a;
    border-color: #26a69a;
    background-color: #e0f2f1;
}

.adminBoard .settingsContainer .manageAccountsContent .leftPanel .userList{
    border: solid 2px #e2e2e2;
    border-radius: 5px;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    gap: 8px;
    max-height: calc(100vh - 250px);
}

.adminBoard .settingsContainer .manageAccountsContent .rightPanel .manageHeaderRow{
    grid-template-columns: 125px 1fr 140px 127px;
}

.adminBoard .settingsContainer .manageAccountsContent .rightPanel .userPermissions{
    grid-template-columns: auto auto 1fr;
    gap: 24px;
}

.adminBoard .settingsContainer .manageAccountsContent .rightPanel .userPermissions .permissionsHeader {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding-bottom: 2px;
}

.adminBoard .settingsContainer .manageAccountsContent .rightPanel .userPermissions .trackBar {
    grid-template-columns: 64px 1fr 1fr;
    gap: 8px;
    width: 100%;
}

.adminBoard .settingsContainer .manageAccountsContent .rightPanel .adminBoardOverlayBlur{
    filter: blur(1px);
    opacity: 0.5;
}

.adminBoard .settingsContainer .manageAccountsContent .rightPanel .adminBoardOverlayWarning{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    background-color: white;
    border: 2px solid gray;
    z-index: 10;
    transform: translate(-50%, -50%);
    grid-template-rows: 40px 200px 1fr 2fr;
    grid-template-columns: 1fr 40px;
}

.adminBoard .settingsContainer .settingsTabContent{
    grid-template-columns: 1fr 3fr;    
}

.adminBoard .settingsContainer .settingsTabContent .settingsLeftPanel {
    grid-row: span 2;
    grid-template-rows: 70px 80px 80px 80px 80px 1fr;
    border-right: 2px solid whitesmoke;
    background-color: whitesmoke;
}

.adminBoard .settingsContainer .settingsTabContent .settingsRightPanel {
    grid-row: span 2;
    border-right: 2px solid whitesmoke;
}

.adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .userPermissions{
    grid-template-columns: auto auto 1fr;
    gap: 24px;
}

.adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .userPermissions .permissionsHeader {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding-bottom: 2px;
}

.adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .userPermissions .trackBar {
    grid-template-columns: 64px 1fr 1fr;
    gap: 8px;
    width: 100%;
}

.adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .settingsWidgetContent{
    padding-top: 8px;
    border: 2px solid lightgray;
    width: 95%;
    height: 95%;
    margin: 20px;
    grid-template-rows: auto auto 1fr auto;
}

.adminBoard .settingsContainer .settingsTabContent .settingsRightPanel .settingsWidgetContent.preferences{
    border: 2px solid lightgray;
    width: 95%;
    height: 95%;
    margin: 20px;
    grid-template-rows: 10% 10% 10% 10% 10%;
}

.adminBoard .settingsContainer .settingsTabContent .settingsLeftPanel .settingsWidget{
    border-bottom: 2px solid #00897b;
    color:#00897b;
    background-color: #e0f2f1;
}

.adminBoard .settingsContainer .settingsTabContent .settingsLeftPanel .settingsWidgetInactive{
    border-bottom: 2px solid lightgray;
    color:gray;
    background-color: white;
}

.adminBoard .settingsContainer .passwordResetContent{
    grid-template-rows: 50px 1fr 50px;
}

/* .adminBoard .format > div{
    grid-template-columns: 300px 1fr;
} */

.adminBoard .format .settingsContainer .leftPanel{
    grid-template-rows: 48px 1fr;
}
.adminBoard .format .settingsContainer .rightPanel{
    grid-template-rows: 48px 1fr 56px;
    position: relative;
}

.adminBoard .settingsContainer .settingsRightPanel .accountHeaderRow{
    grid-template-columns: 125px 1fr auto;
}

.adminBoard .settingsContainer .rightPanel .profileContent{
    grid-template-columns: 2fr 1fr;
}

.adminBoard .rowData .editUserBtn{
    width: 50%;
    height: 70%;
    min-width: 75px;
}

.colSpan2{
    grid-column: span 2;
}

.deleteUser{
    grid-template-columns: repeat(3, 1fr);
    border-color: #e53935;
    color:#e53935;
}
.deleteUser:hover{
    background-color: #ffebee;
    border-color: #c62828;
    color: #c62828;
}

.apiKeyContainer{
    grid-template-rows: 45px 150px 40px 50px 1fr;
}

.generationContainer{
    border: 2px solid gray;
    grid-template-rows: 30px 50px 40px 40px 80px 1fr;
    padding: 20px
}

.generateKeyBtn{
    width: 300px;
}

.apiKeyField{
    border: 2px solid lightgray;
    width: 600px;
    padding: 10px;
}

.apiKeyFieldText{
    width: auto;
    white-space: nowrap;
}

.apiKeyFieldText:hover{
    background-color: whitesmoke;
}

.apiKeyFieldText.clicked{
    background-color: #e0f2f1 !important;
}
