.policyScroller, .notifications, .reportOptions{
    width:50%;
    height:60%;
}

.criteriaHandler, .individualProfile, .adminBoard{
    width:90%;
    height:90%;
}

.uploadSuccess, .uploadHistory{
    width:70%;
    height:70%;
}

.overlay ~ .App {
    filter: blur(2px); /* Adjust the blur radius as needed */
}

.overlay .moduleBlock.criteriaHandler.default > :nth-child(2){
    grid-template-rows: 48px 1fr 56px;
}

.overlay > div{
    transition: 0ms !important;
}

@media (max-height: 600px) {
    .policyScroller, .notifications, .criteriaHandler, .individualProfile, .uploadSuccess{
        width:90%;
        height:90%;
    }    
}

.overlay .moduleBlock > :nth-child(2){
    height:-webkit-fill-available;
}

.settings{
    width:300px;
    height: auto;
    max-height:450px;
    right:16px;
    top:16px;
    position: absolute;
}
.passwordChange{
    width:350px;
    height: auto;
}
.emailPreferences{
    width:550px;
    height: auto;
    min-height: 442px;
}

.emailPreferences > :nth-child(2){
    grid-template-rows: 1fr auto;
    align-items: flex-start;
}

.overlay .listBoard{
    grid-template-rows: 48px 8px 1fr;
}
.overlay .listBoard .wrap{
    overflow: auto;
}
.overlay .listBoard .checkStatus{
    color:inherit;
}

.overlay .listBoard .columnLabels{
    grid-template-columns: 1fr;
    gap:8px;
}
.overlay .listBoard .columnLabels .labels{
    grid-template-columns: repeat(4, 1fr) 36px;
    gap:8px;
    padding-right: 10px;
    padding-left: 10px;
}
.overlay .notifications .listBoard .columnLabels .labels{
    grid-template-columns: 1fr .5fr auto;
    padding-left: 18px;
    padding-right: 10px;
    gap:16px;
}

.overlay .notifications .listBoard .columnLabels .labels.alt{
    padding-right: 36px;
}

.overlay .listBoard .columnLabels .labels.alt{
    padding-right: 32px;
}

.overlay .listBoard .list{
    grid-template-rows: repeat(auto-fill, 48px);
    gap:8px;
}

.overlay .listBoard .list .row{
    grid-template-columns: 1fr;
    gap:8px;
    min-height: 48px;
}
.overlay .listBoard .list .row .data{
    grid-template-columns: repeat(4, 1fr) 36px;
    gap:8px;
    border:solid 2px gray;
    border-radius:5px;
    color:dimgray;
    background-color: white;
}
.overlay .notifications .listBoard .list .row .data{
    grid-template-columns: 1fr .5fr auto;
}
.overlay .notifications .listBoard .list .row .data > div.wIcon{
    grid-template-columns: 48px 1fr;
}
.overlay .listBoard .list .row .data .status{
    grid-template-rows: repeat(2, 1fr);
}

.overlay .listBoard .list .row .progressBar{
    width: calc(100% - 16px);
    height:10px;
    border-radius:8px;
    border:solid 2px gray;
    background-color:white;
    overflow: hidden;
}

.overlay .listBoard .list .row.active.Preparation .data{
    border-color:#f9a825;
    background-color: #fffde7;
    color: #f9a825;
}
.overlay .listBoard .list .row.active.Preparation .progressBar{
    border-color:#f9a825;
}
.overlay .listBoard .list .row.active.Preparation .progressBar .progress{
    background-color:#fdd835 !important;
}

.overlay .listBoard .list .row.active.Valuation .data{
    border-color:#33691e;
    background-color: #f1f8e9;
    color: #33691e;
}
.overlay .listBoard .list .row.active.Valuation .progressBar{
    border-color:#33691e;
}
.overlay .listBoard .list .row.active.Valuation .progressBar .progress{
    background-color:#8bc34a !important;
}

.overlay .listBoard .list .row.active.Contracts .data{
    border-color:#1b5e20;
    background-color: #e8f5e9;
    color: #1b5e20;
}
.overlay .listBoard .list .row.active.Contracts .progressBar{
    border-color:#1b5e20;
}
.overlay .listBoard .list .row.active.Contracts .progressBar .progress{
    background-color:#4caf50 !important;
}

.overlay .listBoard .list .row.active.Closing .data{
    border-color:#1565c0;
    background-color: #e3f2fd;
    color: #1565c0;
}
.overlay .listBoard .list .row.active.Closing .progressBar{
    border-color:#1565c0;
}
.overlay .listBoard .list .row.active.Closing .progressBar .progress{
    background-color:#1e88e5 !important;
}

.overlay .listBoard .list .row.active.Completed .data{
    border-color:#ef6c00;
    background-color: #fff3e0;
    color: #fb8c00;
}
.overlay .listBoard .list .row.active.Completed .progressBar{
    border-color:#ef6c00;
}
.overlay .listBoard .list .row.active.Completed .progressBar .progress{
    background-color:#fb8c00 !important;
}

.overlay .listBoard .list .row.active.Closed .data{
    border-color:#c62828;
    background-color: #ffebee;
    color: #e53935;
}
.overlay .listBoard .list .row.active.Closed .progressBar{
    border-color:#c62828;
}
.overlay .listBoard .list .row.active.Closed .progressBar .progress{
    background-color:#e53935 !important;
}

.overlay .listBoard .list .row.active.OnHold .data{
    border-color:#ff7043;
    background-color: #fbe9e7;
    color: #ff7043;
}
.overlay .listBoard .list .row.active.OnHold .progressBar{
    border-color:#ff7043;
}
.overlay .listBoard .list .row.active.OnHold .progressBar .progress{
    background-color:#ff7043 !important;
}

.overlay .listBoard .list .row .data:hover{
    background-color: #EFEFEF;
}

.overlay .listBoard .list .row .progress{
    position:absolute;
    left:0;
    top:0;
    height:100%;
}

.overlay .settingsOptions{
    grid-template-rows: 1fr 56px;
}

.overlay .settingsOptions :first-child{
    gap: 8px;
}

.overlay .settingsOptions .accountInfo{
    color: dimgray;
}

.overlay .settingsOptions .logout{
    grid-template-columns: repeat(2, 1fr);
}

.overlay .settingsOptions .logout .btnWIcon{
    grid-template-columns: repeat(3, 1fr);
    border-color: #e53935;
    color:#e53935;
}
.overlay .settingsOptions .logout .btnWIcon:hover{
    background-color: #ffebee;
    border-color: #c62828;
    color: #c62828;
}

.overlay .settingsOptions .options{
    grid-template-rows: repeat(auto-fill, 48px);
}

.overlay .settingsOptions .options .option{
    border:solid 2px gray;
    color: dimgray;
}

.overlay .settingsOptions .options .option:hover{
    background-color: #EFEFEF;
}

.passwordChange .form{
    grid-auto-rows: auto;
    gap:8px;
    font-size: 18px;
}

.passwordForm{
    grid-template-rows: 1fr 48px;
}

.passwordForm input{
    border-radius: 5px;
    color:dimgray;
}

.passwordForm .inputField input:focus{
    outline:solid 2px gray;
    border-color: transparent;
    background-color: white;
}

.overlay .notificationsBoard{
    grid-template-rows: 48px 8px 1fr;
}

.preferences{
    grid-auto-rows: auto;
    gap:8px;
    height: max-content;
    color: dimgray;
}

.preferences .trackBar{
    grid-template-columns: 64px repeat(4, 1fr) 64px;
    gap: 8px;
    width: 100%;
}

.preferences .trackBar svg{
    width:48px;
    height:48px;
    color:#00897b;
}

.preferences .trackBar .option{
    border:solid 2px dimgray;
    border-radius: 5px;
    color:dimgray;
}

.preferences .trackBar .option.active{
    color:#26a69a;
    border-color:#26a69a;
    background-color: #e0f2f1;
}

.preferences .trackBar .option:not(.active):hover{
    border-color: #00897b;
    color:#00897b;
}

.preferences .emailToggle{
    grid-template-columns: repeat(2, auto) 1fr;
    gap:8px;
}

.preferences .spacer{
    height:16px;
}

.preferences .ignore .option:not(.active){
    background-color: #EFEFEF !important;
    border: solid 2px gainsboro !important;
    border-radius: 5px !important;
    color:gray;
}

.preferences .ignore .option:not(.active):hover{
    color:#42a5f5 !important;
    border-color:#42a5f5 !important;
    background-color: #e3f2fd !important;
}

.preferences .ignore .option.active{
    background-color: #E2E2E2;
    border: solid 2px dimgray;
    border-radius: 5px;
    color: dimgray;
}

.preferences .ignore svg{
    color: dimgray;
}

.preferences .actionBar{
    grid-template-columns: repeat(3, 1fr);
}

.actionBar{
    grid-template-columns: repeat(3, 1fr);
    height: 48px;
}

.actionBar .btn{
    max-width: 300px;
}