.individualProfile .selectedProfile{
    grid-template-rows: 56px 1fr;
    grid-template-rows: 56px calc(100% - 64px);
}

.individualProfile .selectedProfile.alt{
    grid-template-rows: 1fr;
}

.individualProfile .tabs{
    grid-template-columns: repeat(2, 1fr);
}

.individualProfile .viewBar{
    grid-template-columns: repeat(2, 1fr);
}

.individualProfile .selectedProfile .body{
    grid-template-columns: repeat(2, 1fr);
    gap:32px;
}

.individualProfile .selectedProfile .body .form.info{
    grid-auto-rows: min-content;
    gap:32px;
    width: 100%;
    height: min-content;
}

.individualProfile .selectedProfile .body .form.info .header:first-of-type{
    grid-template-columns: auto auto auto auto;
    color:dimgray;
}

.individualProfile .selectedProfile .body .form .header{
    grid-template-columns: auto auto;
    color:dimgray;
}

.individualProfile .selectedProfile .body .form .header .group{
    background-color: #e8f5e9;
    border: solid 2px transparent;
    color:#4caf50; 
}

.individualProfile .selectedProfile .row{
    grid-template-rows: repeat(2, auto);
    gap:8px;
}

.individualProfile .selectedProfile .row.alt{
    grid-auto-rows: auto;
    gap:8px;
}

.individualProfile .selectedProfile .row .list{
    grid-template-columns: 24px auto;
}

.individualProfile .selectedProfile .rowData{
    gap:8px;
    grid-template-columns: repeat(3, 1fr);
}

.individualProfile .selectedProfile .alt .rowData{
    gap:8px; grid-template-columns: 1fr;
}

.individualProfile .selectedProfile .row .list .vDivider{
    border: 1px dashed #E2E2E2;
    width: 0;
    background-color: transparent;
    margin:unset;
}

.individualProfile .selectedProfile .alt .rowData span span.alt{
    grid-template-columns: auto auto;
    display: inline-grid;
}


.individualProfile .selectedProfile .obituaries{
    grid-template-columns: 300px 1fr;
    gap:8px;
    width: 100%;
}

.individualProfile .selectedProfile .obituaries > .g{
    grid-template-rows: auto 1fr;
}

.individualProfile .selectedProfile .obituaries > .g .header{
    color: dimgray;
}

.individualProfile .selectedProfile .obituaries > .g .header.right{
    grid-template-columns: repeat(3, 1fr);
}

.individualProfile .selectedProfile .obituaries > .g .header > div{
    grid-template-columns: 1fr 1fr;
    width: min-content;
}
.individualProfile .selectedProfile .obituaries > .g .header > .individualName{
    grid-template-columns: unset;
    width: 100%;
}
.deathReceipt{
    grid-template-rows: 24px 1fr;
}

.deathReceipt .header div, .statusReport .header div{
    color:dimgray;
    grid-template-columns: auto auto;
}

.deathReceipt .receipt{
    grid-template-rows: 36px 16px 1fr auto;
    width: 100%;
    height: inherit;
    background-color: white;
    border:solid gainsboro 2px;
}

.receipt .icon svg{
    width: 72px;
    height: 72px;
    color: gray;
}

.individualProfile .selectedProfile .receipt .form{
    grid-template-rows: auto;
    height: min-content;
    font-size:18px;
    grid-template-columns: 24px 1fr 24px;
    grid-auto-rows: min-content;
    row-gap:32px;
    column-gap: 8px;
    color:dimgray
}

.individualProfile .selectedProfile .statusReport .receipt .form{
    width: unset;
}

.deathReceipt .receipt .statement, .statusReport .receipt .statement{
    color: dimgray;
    font-size:18px;
    font-weight: 100;
}

.individualProfile .selectedProfile .receipt .form .listTracker{
    grid-template-rows: 24px 1fr;
}

.receipt .link{
    background-color: #e3f2fd;
    border:solid 2px transparent;
}
.receipt .link.inactive{
    background-color: #EFEFEF;
    border:solid 1px gainsboro;
    color: gray;
}

.receipt .link:not(.inactive):hover{
    border-color: #42a5f5;
}

.statusReport{
    grid-template-rows: 24px 1fr;
}

.statusReport .receipt{
    border: solid 2px #E2E2E2;
    grid-template-rows: 48px auto 1fr 36px;
    background-color: white;
    gap:32px;
}

.statusReport .receipt .form .data{
    grid-template-rows: auto auto;
}

.statusReport .receipt .form .value.circle{
    border-radius: 50%;
    border:solid 2px dimgray;
    width: 120px;
    height: 120px;
}

.statusReport .receipt .form .data .value{
    font-size: 48px;
}

.statusReport .receipt .form .data .prompt{
    grid-template-columns: auto auto;
}

.statusReport .receipt .footer{
    grid-template-columns: auto auto auto;
    color:dimgray;
    border:solid 2px dimgray;
    border-radius: 24px;
}

.statusReport .receipt .footer .prompt{
    font-weight: 100;
    width: max-content;
}

.obituaryExport{
    grid-template-columns: 1fr auto 8px auto 1fr;
}

.obituaryString{
    border: solid 2px gainsboro;
    height: auto;
    background-color: white;
    color: dimgray;
    line-height: 32px;
}

.individualProfile .obituaryBlock.active {
    border-color: #26a69a;
    color: #26a69a;
}
.individualProfile .obituaryBlock {
    border: 2px solid #e2e2e2;
    border-radius: 5px;
    color: gray;
    grid-template-columns: 1fr 48px;
    height: 64px;
    background-color: white;
}

/* .individualProfile .obituaryBlock .action{
    display: none;
} */
.individualProfile .obituaryBlock:not(.alt):hover {
    background-color: #e0f2f1;
    border-color: #26a69a;
    color: #26a69a;
    grid-template-columns: 1fr 48px;
}
.individualProfile .obituaryBlock:hover .action{
    display: inline-grid;
    border:solid 2px transparent;
    border-radius: 50%;
    border: solid 2px transparent;
    aspect-ratio: 1;
}

.individualProfile .obituaryBlock .action:hover{
    color: #004d40;
    background-color: white;
    border-color: #004d40;
}

.individualProfile .obituaryBlock.active {
    border-color: #26a69a;
    color: #26a69a;
}

/* .individualProfile .obituaryBlock {
    border: 2px solid #e2e2e2;
    border-radius: 5px;
    color: gray;
    grid-template-columns: 1fr;
    height: 64px;
} */

.matchingText{
    background-color: #e0f2f1;
    border: solid 2px #26a69a;
    color: #26a69a;
    white-space: nowrap;
}

.individualProfile .selectedProfile .alt .rowData.linkHandler{
    grid-template-columns: 1fr auto;
}

.individualProfile .disableIndividual{
    color: #c62828;
    border: solid 2px #c62828;
    position: sticky; 
    bottom: 0; 
    background-color: white; 
    z-index: 10; 
    padding: 10px; 
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
}

.individualProfile .disableIndividual:hover{
    background-color: #ffebee;
}
